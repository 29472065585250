@use '../../base/vars'as *;
@use "sass:math";

.voice {
    background: $bg-color;

    .voice__container {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(68);
        margin-top: rem(50);

        @include media(sm) {
            gap: rem(16);
            margin-top: rem(74);
        }
    }
}
