@use '../base/vars'as *;
@use "sass:math";

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $header;
    width: 100%;
    padding: rem(16) 0;
    background: $red;
    color: $white;
    font-size: rem(17);
    min-height: rem(40);

    .header__logo {
        display: block;
        position: absolute;
        top: rem(12);
        left: rem(30);
        width: 100%;
        max-width: rem(164);
        aspect-ratio: calc(333/126);

        @include media(md) {
            max-width: vw(333);
        }

        @include media(xl) {
            max-width: rem(333);
        }
    }

    .header__heading {
        display: none;

        @include media(md) {
            display: block;
            margin-left: rem(40);
            padding-left: vw(333);
        }

        @include media(xl) {
            padding-left: rem(333);
        }
    }

    .header__buttons {
        display: flex;
        gap: rem(4);
        position: absolute;
        top: rem(12);
        right: rem(30);
        width: 100%;
        max-width: rem(126);

        @include media(md) {
            max-width: vw(326);
        }

        @include media(xl) {
            max-width: rem(326);
        }
    }

    .header__search,
    .header__contact {
        position: relative;
        width: 100%;
        max-width: rem(63);
        aspect-ratio: calc(63/58);

        @include media(md) {
            max-width: vw(163);
            aspect-ratio: calc(163/126);
        }
    }

    .header__search {}

    .header__contact {}
}
