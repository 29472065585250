@use '../../base/vars'as *;
@use "sass:math";

.step {
    position: relative;

    .step__bg01 {
        position: absolute;
        width: 100%;
        top: rem(36);
        right: 0;
        transform: translateX(40%);
        max-width: rem(98);
        aspect-ratio: calc(193/62);
        
        @include media(sm) {
            max-width: rem(193);
            top: vw(140);
            right: vw(110);
        }

        @include media(xl) {
            top: rem(140);
            right: rem(110);
        }
    }

    .step__bg02 {
        position: absolute;
        width: 100%;
        max-width: rem(136);
        aspect-ratio: calc(136/46);
        display: none;
        
        @include media(sm) {
            display: block;
            bottom: rem(190);
            left: vw(125);
        }

        @include media(xl) {
            left: rem(125);
        }
    }

    .step__bg03 {
        position: absolute;
        width: 100%;
        aspect-ratio: calc(139/191);
        z-index: $main;
        bottom: rem(10);
        right: rem(42);
        max-width: rem(55);

        @include media(sm) {
            max-width: rem(139);
            right: vw(85);
        }

        @include media(xl) {
            right: rem(85);
        }
    }

    .step__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(30);
        margin: 0 auto;
        margin-top: rem(30);
        max-width: rem(343);

        @include media(sm) {
            grid-template-columns: repeat(3, 1fr);
            gap: rem(30);
            margin-top: rem(30);
            max-width: 100%;
        }

        .step__img {
            position: relative;
            width: 100%;
            aspect-ratio: calc(343/402);

            @include media(sm) {
                aspect-ratio: calc(316/662);
            }
        }
    }

    .step__container {
        background: $white;
        padding: rem(40) rem(28);
        border-radius: rem(20);
        margin-top: rem(56);

        @include media(sm) {
            padding: rem(50) rem(60);
        }

        .step__logos {
            position: relative;
            width: 100%;
            max-width: rem(612);
            aspect-ratio: calc(253/99);
            margin: 0 auto;

            @include media(sm) {
                aspect-ratio: calc(612/51);
            }
        }

        .step__text {
            font-size: rem(12);
            font-weight: 600;
            text-align: center;
            margin-top: rem(16);
            line-height: calc(20/12);

            @include media(sm) {
                font-size: rem(15);
                margin-top: rem(32);
            }

            .small {
                font-weight: 300;
            }
        }
    }
}
