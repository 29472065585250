@use '../../base/vars'as *;
@use "sass:math";

.web {
    .web__movie {
        position: relative;
        width: 100%;
        aspect-ratio: calc(952/535);
        border: rem(8) solid $white;
        border-radius: rem(20);
        margin-top: rem(36);
        overflow: hidden;

        @include media(sm) {
            border: rem(24) solid $white;
            margin-top: rem(56);
        }
    }

    .web__text {
        font-size: rem(16);
        font-weight: 700;
        text-align: center;
        line-height: calc(45/25);
        margin-top: rem(27);

        @include media(sm) {
            margin-top: rem(60);
            font-size: rem(25);
        }

        strong {
            font-size: 1.2em;
        }
    }

    .web__contact {
        position: relative;
        width: 100%;
        aspect-ratio: calc(682/124);
        margin-top: rem(36);

        @include media(md) {
            aspect-ratio: calc(1010/130);
            margin-top: rem(50);
        }
    }
}
