@use "../../base/vars"as *;
@use "sass:math";

.button {
    font-size: rem(12);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 9999px;
    background: $yellow;
    padding: rem(20);
    transition: all .3s ease-in-out;

    @include media(sm) {
        @include font-vw(13, 1366);
    }

    @include media(xl) {
        @include font-vw(20, 1920);
    }

    i {
        padding-right: rem(10);
    }

    &:hover {
        background: $navy;
        color: $white;
    }
}
