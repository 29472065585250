@use "sass:math";
@use '../../base/vars'as *;

.splide__slide {
    position: relative;
    background: $white;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.main-slider {

    .splide__slide {
        a {
            &::before {
                display: block;
                content: "";
                position: absolute;
                right: rem(8);
                bottom: rem(8);
                width: rem(30);
                height: rem(30);
                background: url(../../common/svg/icon_zoom.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: $main;

                @include media(sm) {
                    right: rem(16);
                    bottom: rem(16);
                    width: rem(38);
                    height: rem(38);
                }
            }
        }
    }

    .main-slider__index {
        position: absolute;
        bottom: rem(8);
        left: 50%;
        transform: translateX(-50%);
        background: rgba($black, .3);
        padding: rem(8) rem(16);
        border-radius: 9999px;
        color: $white;

        @include media(sm) {
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0, 0);
            display: flex;
            justify-content: space-between;
            background: $green;
            padding: rem(8) rem(18);
            margin-top: rem(22);
            border-radius: 0;
        }
    }

    .splide__arrow {
        width: rem(30);
        height: rem(30);
        opacity: 1;
        background: url(../../common/svg/splide_arrow01.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include media(sm) {
            width: rem(38);
            height: rem(38);
        }
    }

    .splide__arrow--next {
        font-size: rem(8);
        transform: scaleX(-100%) translateY(-50%);

        @include media(sm) {
            font-size: 1em;
        }
    }
}

.thumbnail-slider {
    display: none;
    margin: rem(22) rem(28) 0;

    @include media(sm) {
        display: block;
    }

    .splide__arrow--prev {
        left: rem(-28);
    }

    .splide__arrow--next {
        right: rem(-28);
        transform: scaleX(-100%) translateY(-50%);
    }

    .splide__arrow {
        width: rem(16);
        height: rem(55);
        border-radius: 0;
        opacity: 1;
        background: url(../../common/svg/splide_arrow02.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
