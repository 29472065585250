@use '../../base/vars'as *;
@use "sass:math";

.about {

    .about__heading {
        font-size: rem(15);
        font-weight: 700;
        text-align: center;

        @include media(md) {
            font-size: rem(29);
        }

        span {
            padding-bottom: .5em;
        }
    }

    .about__text {
        font-size: rem(15);
        font-weight: 700;
        text-align: center;
        line-height: calc(45/25);
        margin-top: rem(32);
        letter-spacing: -.05em;
        
        @include media(md) {
            margin-top: rem(50);
            font-size: rem(25);
        }
        
        .large {
            letter-spacing: .1em;
            line-height: calc(39/30);
            font-size: rem(30);
            font-weight: 900;

            @include media(md) {
                font-size: rem(62);
            }
        }

        .middle {
            line-height: calc(33/20);
            font-size: rem(20);

            @include media(md) {
                font-size: rem(45);
            }
        }
    }

    .about__comic {
        position: relative;
        width: 100%;
        aspect-ratio: calc(1000/2737);
        margin-top: rem(40);
        
        @include media(sm) {
            margin-top: rem(140);
        }
    }
}
