@use '../../../base/vars'as *;
@use "sass:math";

.article {
    padding: rem(90) 0;
    line-height: 2;
    background: $bg-color;

    @include media(sm) {
        padding: rem(180) 0;
    }

    .article_section {}

    .article_title {
        font-size: rem(24);

        @include media(sm) {
            font-size: rem(36);
        }
    }

    .article_description {
        margin-top: 2em;
        font-size: rem(16);

        @include media(sm) {
            font-size: rem(20);
        }
    }

    .single__contact_wrapper {
        padding-top: 0 !important;

        @include media(sm) {
            padding-top: 0 !important;
        }
    }
}
