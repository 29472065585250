@use "../../../base/vars"as *;
@use "sass:math";

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading__text {
        text-align: center;
    }

    .heading__img {
        position: relative;
        margin-top: rem(16);
        text-align: center;
        width: 100%;
    }

    .heading_search {
        width: 100%;
        max-width: rem(136);
        aspect-ratio: calc(277/77);

        @include media(sm) {
            max-width: rem(264);
        }
    }

    .heading_step {
        max-width: rem(84);
        aspect-ratio: calc(84/33);

        @include media(sm) {
            max-width: rem(162);
        }
    }

    .heading_archivements {
        max-width: rem(276);
        aspect-ratio: calc(276/33);

        @include media(sm) {
            max-width: rem(532);
        }
    }

    .heading_voice {
        width: 100%;
        max-width: rem(106);
        aspect-ratio: calc(106/33);

        @include media(sm) {
            max-width: rem(202);
        }
    }

    .heading_faq {
        width: 100%;
        max-width: rem(65);
        aspect-ratio: calc(65/33);

        @include media(sm) {
            max-width: rem(125);
        }
    }

    .heading_web {
        width: 100%;
        max-width: rem(134);
        aspect-ratio: calc(134/33);

        @include media(sm) {
            max-width: rem(261);
        }
    }
}

.subText {
    font-size: rem(17);
    font-weight: 700;
    text-align: center;
    margin-top: rem(28);
    line-height: calc(26/17);

    @include media(sm) {
        font-size: rem(25);
    }
}
