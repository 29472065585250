@use "sass:math";
@use '../../base/vars'as *;

.gmapballoon {
    font-size: rem(14);
    font-weight: 500;
    display: grid;
    grid-template-areas:
        'areaA'
        'areaB'
        'areaC';
    gap: rem(12) rem(18);

    &+.gmapballoon {
        margin-top: 3em;
    }

    .areaA {
        grid-area: areaA;
        display: flex;
        align-items: center;
        gap: rem(14);
        padding-bottom: rem(12);
        border-bottom: rem(1) solid $black;
    }

    .areaB {
        grid-area: areaB;
        display: grid;
        grid-template-columns: rem(120) 1fr;
        gap: rem(12);
    }

    .areaC {
        grid-area: areaC;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(6);
    }

    .gmapbtn {
        position: relative;
        width: 100%;
        aspect-ratio: calc(199/57);

        img {
            border: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    .gmapballoon__shubetsu {
        display: inline-block;
        text-align: center;
        color: $white;
        background: $green;
        padding: rem(2) rem(10);

        &+.gmapballoon__shiku {
            margin-top: rem(10);
        }

        &.shubetsu1101,
        &.shubetsu1104 {
            background: $green;
        }

        &.shubetsu1201 {
            background: $red_d;
        }
    }

    .gmapballoon__shiku {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background: $navy;
        padding: rem(7) rem(10);
    }

    .gmaptitle {
        font-size: rem(14);
        font-weight: 700;
    }

    .gmap_img {
        position: relative;
        width: 100%;
        aspect-ratio: calc(120/120);
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }
    }

    .subtitle {
        padding-right: .5em;
        margin-right: .5em;
        font-weight: 700;
        font-size: rem(14);
        color: $black;
    }

    &.single {
        display: grid;
        grid-template-columns: rem(120) 1fr;
        grid-template-areas:
            'areaB areaB'
            'areaA areaC';
        gap: rem(12) rem(18);

        .msg {
            display: none;
        }

        .areaA {
            grid-area: areaA;
        }

        .areaB {
            display: flex;
            align-items: center;
            gap: rem(14);
            padding-bottom: rem(12);
            border-bottom: rem(1) solid $black;
            grid-area: areaB;
        }

        .areaC {
            display: flex;
            flex-direction: column;
            gap: rem(4);
            font-size: rem(14);
            grid-area: areaC;
        }
    }
}
