@use '../../base/vars'as *;
@use "sass:math";

.single,
.contact {
    .single__main {}

    .single__heading {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);
        background: $white;
        border: rem(5) solid $red;
        border-radius: rem(10);
        padding: rem(28) rem(36);
        filter: drop-shadow(rem(4) rem(4) 0 rgba(0, 0, 0, 0.3));

        @include media(sm) {
            flex-wrap: nowrap;
            gap: rem(10);
            padding: rem(28) rem(36);
        }

        .single__shubetsu {
            color: $white;
            background: $green;
            padding: rem(4) rem(16);

            &.shubetsu1101 {
                background: $green;
            }
        }

        .single__shozaichi {
            color: $white;
            background: $gray_d;
            padding: rem(4) rem(16);
        }

        .single__title {
            font-size: rem(22);
            font-weight: 600;
        }
    }

    .single__bukken_copy {
        padding: rem(36) 0;
        font-size: rem(20);
    }

    .single__wrapper {
        position: relative;
        padding-top: rem(86);
        padding-bottom: rem(20);
        background: $bg-color;

        @include media(sm) {
            padding-bottom: rem(74);
            padding-top: rem(140);
        }

        .single__bg {
            display: none;
            position: absolute;
            bottom: 0;
            right: rem(40);
            width: 100%;
            max-width: rem(114);
            aspect-ratio: calc(114/274);
            transform: translateY(30%);
            z-index: $main;

            @include media(sm) {
                display: block;
                right: vw(95);
            }

            @include media(xl) {
                right: rem(95);
            }
        }
    }

    .single__container {
        display: grid;
        gap: rem(20);
        grid-template-columns: 1fr;

        @include media(md) {
            gap: rem(40);
            grid-template-columns: 1fr 1fr;
        }
    }

    .single__thumbnail {
        img {
            width: 100%;
            height: auto;
        }
    }

    .single__right {
        background: $white;
        padding: rem(32) rem(20);
        border-radius: rem(10);
        margin-top: rem(10);
        line-height: 1.3;

        @include media(sm) {
            margin-top: 0;
            padding: rem(28);
        }

        .single__right_inner {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(10);
            border-top: rem(1) solid $gray_l;
            padding: rem(14) 0;

            @include media(sm) {
                gap: 0;
                grid-template-columns: rem(86) 1fr;
            }

            &.strong {
                display: block;
                font-weight: 700;
                border: none;

                .single__right_title {
                    padding-bottom: rem(14);
                }

                .single__right_text {
                    font-size: rem(22);
                    color: $red;
                    border-top: rem(1) solid $gray_l;
                    padding-top: rem(14);
                }
            }

            &:last-of-type {}
        }

        .single__right_title {
            font-size: rem(12);
            font-weight: 700;

            @include media(sm) {
                font-size: rem(15);
            }
        }

        .single__right_text {
            font-size: rem(12);

            @include media(sm) {
                font-size: rem(15);
            }

            .small {
                font-size: .7em;
            }
        }
    }

    .single__button {
        width: 100%;
        max-width: rem(600);
        margin: 0 auto;
        margin-top: 0;
        
        @include media(md) {
            max-width: rem(1010);
            margin-top: vw(-224);
        }

        @include media(xl) {
            margin-top: rem(-224);
        }

        a {
            display: block;
            position: relative;
            width: 100%;
            aspect-ratio: calc(341/62);
            background: url(../img/common/img_contact_sp.png);
            background-size: contain;
            background-repeat: no-repeat;

            @include media(sm) {
                aspect-ratio: calc(1010/130);
                background-image: url(../img/common/img_contact.png);
            }
        }
    }

    .single__notice {
        background: $white;
        line-height: 1.5;
        border-radius: rem(20);

        @include media(sm) {
            border-radius: rem(20);
            padding: rem(50);
            margin-top: rem(80);
        }
    }

    .single__detail {
        position: relative;
        background: $yellow;
        padding-top: rem(82);

        @include media(sm) {
            padding-top: vw(224);
        }

        @include media(xl) {
            padding-top: rem(224);
        }

        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: vw(224) vw(800, 1600) 0 vw(800, 1600);
            border-color: $bg-color transparent transparent transparent;
        }

        .single__detail_wrapper {
            position: relative;
            z-index: $main;
            padding-bottom: rem(16);

            @include media(sm) {
                padding-bottom: 0;
            }
        }
    }

    .single__data {

        @include media(sm) {}

        .single__data_heading {
            margin-top: rem(30);

            @include media(sm) {
                margin-top: rem(50);
            }
        }

        .single__data_wrapper {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0 rem(36);
            background: $white;
            border-radius: rem(20);
            padding: rem(38) rem(20);
            margin-top: rem(12);

            @include media(sm) {
                padding: rem(50);
                margin-top: rem(30);
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .single__data_inner {
            .single__data_container {
                display: grid;
                grid-template-columns: 1fr;
                padding: rem(12);
                border-top: rem(1) solid $gray_l;
                gap: rem(8);

                @include media(sm) {
                    gap: rem(0);
                    grid-template-columns: rem(110) 1fr;
                    padding: rem(15) rem(16) rem(15) 0;
                }

                &:last-of-type {
                    border-bottom: rem(1) solid $gray_l;
                }
            }

            .single__data_title,
            .single__data_text {
                font-size: rem(15);

                @include media(sm) {}
            }

            .single__data_title {
                font-weight: 700;
            }

            .single__data_text {
                line-height: 1.3;

                .setsubi_dat {
                    display: inline-block;
                    position: relative;
                    padding-left: 1em;

                    &::before {
                        position: absolute;
                        top: 50%;
                        left: 0.3em;
                        transform: translate(-50%, -50%);
                        display: block;
                        content: "・";
                    }

                    &+.setsubi_dat {}
                }
            }
        }
    }

    .single__map {
        position: relative;
        padding-top: rem(90);
        background: $blue_l;

        @include media(sm) {
            padding-top: rem(60);
            padding-top: vw(284);
            margin-top: vw(-160);
        }

        @include media(xl) {
            padding-top: rem(60);
            padding-top: rem(284);
            margin-top: rem(-160);
        }

        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: vw(224) vw(800, 1600) 0 vw(800, 1600);
            border-color: $yellow transparent transparent transparent;
        }

        .single__map_wrapper {
            margin-top: rem(58);

        }

        .single__map_inner {
            width: 100%;
            aspect-ratio: calc(1000/373);
            padding-bottom: rem(24);

            @include media(sm) {
                padding-bottom: 0;
            }
        }

        .single__map_container {
            background: $white;
            border-radius: rem(20);
            padding: rem(30) rem(20);

            @include media(sm) {
                padding: rem(60) rem(50);
            }
        }

        .single__mapSub {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(16);

            @include media(sm) {
                grid-template-columns: repeat(3, 1fr);
                gap: rem(27);
            }

            .single__mapSub_info {
                display: grid;
                grid-template-columns: rem(134) 1fr;
                gap: rem(16);

                @include media(sm) {
                    display: flex;
                    flex-direction: column;
                    gap: rem(16);
                }
            }

            .single__mapSub_img {
                position: relative;
                width: 100%;
                aspect-ratio: calc(283/177);

                .img {
                    width: 100%;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .single__mapSub_text {
                line-height: 1.3;
            }
        }

        .single__mapSub_list {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(10) rem(60);
            background: $water_d;
            padding: rem(16);
            margin-top: rem(20);

            @include media(sm) {
                grid-template-columns: repeat(2, 1fr);
                padding: rem(28) rem(30);
                margin-top: rem(50);
            }

            .single__mapSub_item {
                position: relative;
                padding-left: 1.2em;
                line-height: 1.3;

                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    top: .1em;
                    left: 0;
                    width: .8em;
                    height: .8em;
                    border-radius: 100%;
                    background: $black;
                }
            }
        }

        .map_canvas {
            margin-top: rem(22);
        }

        .single__map_imageList {

            #single_fudou_img {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: rem(6);
                margin-top: rem(20);
                padding-bottom: rem(20);

                @include media(sm) {
                    grid-template-columns: repeat(6, 1fr);
                    gap: vw(20, $viewport);
                }

                @include media(xl) {
                    gap: rem(20);
                }

                a {
                    display: block;
                    position: relative;
                    aspect-ratio: calc(143/90);
                    overflow: hidden;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        height: auto;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

    .single__contact {}

    .wpcf7-spinner {
        display: none;
    }

    .single__submitBtn {
        background: none;
    }
}

.single {
    .single__contact {
        background: $green_l;

        @include media(sm) {
            background-size: 100% rem(270);
        }
    }

    .single__contact_wrapper {
        position: relative;
        padding-top: rem(90);

        @include media(sm) {
            padding-top: rem(244);
        }

        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: vw(224) vw(800, 1600) 0 vw(800, 1600);
            border-color: $blue_l transparent transparent transparent;
        }
    }
}
