@use "sass:math";
@use './vars'as *;

// フォント
.font-noto {
    font-family: $font-noto;
}

.font-ibm {
    font-family: $font-ibm;
}

// PC・SP切り替え

._forPC {
    display: none !important;

    @include media(md) {
        display: inherit !important;
    }
}

._forSP {

    @include media(md) {
        display: none !important;
    }
}

// ホバー
.hover {
    transition: opacity .3s ease-out;

    @include hover {
        opacity: .8;
    }
}


// display
.inline-block {
    display: inline-block;
}


// wrapper
.wrapper {
    width: 100%;
    max-width: rem(1040);
    margin: 0 auto;
    padding: 0 rem(20);

    @include media(md) {
        max-width: rem(1100);
        padding: 0 rem(50);
    }
}


// margin / padding
.mx-full {
    margin: 0 calc(50% - 50vw);
}

.px-full {
    padding: 0 calc(50vw - 50%);
}

//width-のクラス（%単位）
@for $i from 1 through 100 {
    .w-#{$i} {
        width: $i * 1%;
    }
}

//width-のクラス（%単位）forPC
@include media(md) {
    @for $i from 1 through 100 {
        .w-#{$i}__pc {
            width: $i * 1%;
        }
    }
}

.emphasis {
    position: relative;
    display: inline-block;
    font-size: rem(16);
    font-weight: 700;

    @include media(sm) {
        font-size: vw(32, $viewport);
    }

    @include media(xl) {
        font-size: rem(32);
    }

    &::before,
    &::after {
        display: block;
        content: "";
        width: 100%;
        max-width: rem(32);
        aspect-ratio: calc(32/32);
        background: url(../../common/svg/emphasis.svg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-100%, -100%);
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -100%) scaleX(-1);
    }

    &.__other {

        &::before,
        &::after {
            display: block;
            content: "";
            width: 100%;
            max-width: rem(21);
            aspect-ratio: calc(21/21);
            background: url(../../common/svg/emphasis_other.svg);
            background-repeat: no-repeat;
            background-size: cover;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-150%, -50%) scaleX(-1);
        }

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(150%, -50%);
        }
    }
}


.underLine {
    background: linear-gradient(transparent 80%, $yellow 80%);

    &.__orange {
        background: linear-gradient(transparent 80%, $orange 80%);
    }
}

.c__blue {
    color: $blue;
}
.c__red {
    color: $red;
}
