@use '../../base/vars'as *;
@use "sass:math";

.faq {
    .faq__container {
        display: grid;
        gap: rem(32);
        padding: rem(38) rem(20);
        background: $white;
        border-radius: rem(24);
        margin-top: rem(160);

        @include media(sm) {
            padding: rem(27) rem(33) rem(54);
        }
    }
}
