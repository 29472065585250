@use '../../base/vars'as *;
@use "sass:math";

.kv {
    background: $bg-color;

    .kv__img {
        position: relative;
        width: 100%;
        aspect-ratio: calc(375/469);

        @include media(sm) {
            aspect-ratio: calc(1600/1156);
        }
    }

    .kv__icon {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 40%);
        width: 100%;
        max-width: rem(61);
        aspect-ratio: calc(128/141);

        @include media(md) {
            max-width: vw(128);
        }

        @include media(xl) {
            max-width: rem(128);
        }
    }
}
