@use "sass:math";

///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-ibm: 'IBM Plex Sans JP',
sans-serif;
$font-noto: 'Noto Sans JP',
sans-serif;
$font-outfit: 'Outfit',
sans-serif;
$font-zenkaku: 'Zen Kaku Gothic Antique',
sans-serif;
$font-oswald: "Oswald", sans-serif;

$line-height: 1.5;


///*------------------------------------*//
//    Colors
//*------------------------------------*//
$text:#333333;
$bg-color:#FFFAE8;

$white: #fff;
$black: #000;
$yellow: #FFDF18;
$water:#E3F6F5;
$water_d:#E1F2FB;
$blue:#009CFF;
$blue_l: #A3D7F4;
$red: #FF0009;
$red_l: #FF3250;
$red_d: #E53170;
$navy:#272343;
$green: #76BB35;
$green_l: #A4D78A;
$green_ll: #EAF4E1;
$green_d: #0284A9;
$beige:#FCEBAC;
$gray:#707070;
$gray_d:#4D4D4D;
$gray_l:#C9C9C9;
$gray_ll:#F4F4F4;
$orange:#F6C562;

///*------------------------------------*//
//   wrapper width
//*------------------------------------*//
$viewport: 1920;
$content: 1000;


///*------------------------------------*//
//   z-index
//*------------------------------------*//
$bg: 1;
$main: 10;
$menu: 100;
$header: 1000;
$modal: 10000;



///*------------------------------------*//
//     breakpoints
//*------------------------------------*//
$breakpoints: (sm: 'screen and (min-width: 769px)',
    md: 'screen and (min-width: 1080px)',
    lg: 'screen and (min-width: 1366px)',
    xl: 'screen and (min-width: 1920px)',
) !default;


///*------------------------------------*//
//     dropshadow
//*------------------------------------*//
$dropshadows: (sm: 0px 0px 6px,
) !default;



///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//フォントサイズ計算
@function rem($size, $base: 16) {
    @return math.div($size, $base)+rem;
}

//pxをvwに変換するfunction
@function vw($size, $viewport: 1920) {
    $rate: calc(100 / $viewport);
    @return $rate * $size * 1vw;
}

//フォント用のmixin
@mixin font-vw($font_size: 10, $viewsize: 769) {
    font-size: vw($font_size, $viewsize);

    @include media(xl) {
        font-size: rem($font_size);
    }
}

//画像widthをvwにするmixin
@mixin width-vw($width) {

    @include media(lg) {
        width: vw($width, $viewport);
    }

    @include media(xl) {
        width: rem($width);
    }
}

@mixin hover {
    &:focus {
        @content;
    }

    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}


// lineheightを除外するmixin
@mixin line-space($line-height:1.5) {

    &::before,
    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
    }

    &::before {
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }

    &::after {
        margin-bottom: calc((1 - #{$line-height}) * 0.5em);
    }
}

@mixin line-space-t($line-height:1.5) {

    &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
    }

    &::before {
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
}

@mixin line-space-b($line-height:1.5) {

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
    }

    &::after {
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
}

//dropshadow
@mixin dropshadow($dropshadow, $color:$black) {
    filter: drop-shadow(map-get($dropshadows, $dropshadow) rgba($color, .2));
}
