@use "../../base/vars"as *;
@use "sass:math";

.faqBox {

    .faqBox__container {
        display: grid;
        grid-template-columns: rem(30) 1fr;
        gap: rem(16);

        @include media(sm) {
            gap: rem(29) rem(16);
            grid-template-columns: vw(42, $viewport) 1fr;
            padding: rem(20) 0;
        }

        @include media(xl) {
            grid-template-columns: rem(42) 1fr;
        }

        &.__question {
            padding-top: 0;
            padding-bottom: rem(24);
            font-weight: 700;

            @include media(sm) {
                padding-bottom: rem(20);
            }

            .faqBox__icon {
                background: $green;
            }

            .faqBox__text {
                @include media(sm) {
                    font-size: rem(18);
                }
            }
        }

        &.__answer {
            border-top: rem(3) dotted $red;
            border-bottom: rem(2) solid $gray_l;
            padding-bottom: rem(32);
            padding-top: rem(24);

            @include media(sm) {
                padding-top: rem(20);
            }

            .faqBox__icon {
                background: $orange;
            }
        }

        .faqBox__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem(30);
            height: rem(30);
            font-size: rem(18);
            font-weight: 700;
            border-radius: 100%;
            color: $white;

            @include media(sm) {
                width: vw(42, $viewport);
                height: vw(42, $viewport);
                font-size: vw(27, $viewport);
            }

            @include media(xl) {
                width: rem(42);
                height: rem(42);
                font-size: rem(27);
            }
        }
    }

    .faqBox__text {
        font-size: rem(14);
        line-height: calc(27/15);

        @include media(sm) {
            font-size: rem(15);
        }
    }

    &:last-child {
        .__answer {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}
