@use 'vars'as *;

html {
	scroll-behavior: smooth;
}

body {
    font-family: $font-ibm;
}

input,
select,
button,
textarea {
    box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
input[type=number],
input[type=submit],
input[type=button],
input[type=reset],
textarea,
button,
select {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
}

input[type=submit],
input[type=button],
input[type=reset],
button,
label[for] {
    cursor: pointer;
}

::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}


a {
    text-decoration: none;
    color: inherit;
}

.img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

ul,
ol {
    list-style: none;
}

main {
}
