@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input, select {
  vertical-align: middle;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "IBM Plex Sans JP", sans-serif;
}

input,
select,
button,
textarea {
  box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
input[type=number],
input[type=submit],
input[type=button],
input[type=reset],
textarea,
button,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

input[type=submit],
input[type=button],
input[type=reset],
button,
label[for] {
  cursor: pointer;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: inherit;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

ul,
ol {
  list-style: none;
}

.font-noto {
  font-family: "Noto Sans JP", sans-serif;
}

.font-ibm {
  font-family: "IBM Plex Sans JP", sans-serif;
}

._forPC {
  display: none !important;
}
@media screen and (min-width: 1080px) {
  ._forPC {
    display: inherit !important;
  }
}

@media screen and (min-width: 1080px) {
  ._forSP {
    display: none !important;
  }
}

.hover {
  transition: opacity 0.3s ease-out;
}
.hover:focus {
  opacity: 0.8;
}
@media (hover: hover) {
  .hover:hover {
    opacity: 0.8;
  }
}

.inline-block {
  display: inline-block;
}

.wrapper {
  width: 100%;
  max-width: 65rem;
  margin: 0 auto;
  padding: 0 1.25rem;
}
@media screen and (min-width: 1080px) {
  .wrapper {
    max-width: 68.75rem;
    padding: 0 3.125rem;
  }
}

.mx-full {
  margin: 0 calc(50% - 50vw);
}

.px-full {
  padding: 0 calc(50vw - 50%);
}

.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-7 {
  width: 7%;
}

.w-8 {
  width: 8%;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-11 {
  width: 11%;
}

.w-12 {
  width: 12%;
}

.w-13 {
  width: 13%;
}

.w-14 {
  width: 14%;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.w-19 {
  width: 19%;
}

.w-20 {
  width: 20%;
}

.w-21 {
  width: 21%;
}

.w-22 {
  width: 22%;
}

.w-23 {
  width: 23%;
}

.w-24 {
  width: 24%;
}

.w-25 {
  width: 25%;
}

.w-26 {
  width: 26%;
}

.w-27 {
  width: 27%;
}

.w-28 {
  width: 28%;
}

.w-29 {
  width: 29%;
}

.w-30 {
  width: 30%;
}

.w-31 {
  width: 31%;
}

.w-32 {
  width: 32%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-35 {
  width: 35%;
}

.w-36 {
  width: 36%;
}

.w-37 {
  width: 37%;
}

.w-38 {
  width: 38%;
}

.w-39 {
  width: 39%;
}

.w-40 {
  width: 40%;
}

.w-41 {
  width: 41%;
}

.w-42 {
  width: 42%;
}

.w-43 {
  width: 43%;
}

.w-44 {
  width: 44%;
}

.w-45 {
  width: 45%;
}

.w-46 {
  width: 46%;
}

.w-47 {
  width: 47%;
}

.w-48 {
  width: 48%;
}

.w-49 {
  width: 49%;
}

.w-50 {
  width: 50%;
}

.w-51 {
  width: 51%;
}

.w-52 {
  width: 52%;
}

.w-53 {
  width: 53%;
}

.w-54 {
  width: 54%;
}

.w-55 {
  width: 55%;
}

.w-56 {
  width: 56%;
}

.w-57 {
  width: 57%;
}

.w-58 {
  width: 58%;
}

.w-59 {
  width: 59%;
}

.w-60 {
  width: 60%;
}

.w-61 {
  width: 61%;
}

.w-62 {
  width: 62%;
}

.w-63 {
  width: 63%;
}

.w-64 {
  width: 64%;
}

.w-65 {
  width: 65%;
}

.w-66 {
  width: 66%;
}

.w-67 {
  width: 67%;
}

.w-68 {
  width: 68%;
}

.w-69 {
  width: 69%;
}

.w-70 {
  width: 70%;
}

.w-71 {
  width: 71%;
}

.w-72 {
  width: 72%;
}

.w-73 {
  width: 73%;
}

.w-74 {
  width: 74%;
}

.w-75 {
  width: 75%;
}

.w-76 {
  width: 76%;
}

.w-77 {
  width: 77%;
}

.w-78 {
  width: 78%;
}

.w-79 {
  width: 79%;
}

.w-80 {
  width: 80%;
}

.w-81 {
  width: 81%;
}

.w-82 {
  width: 82%;
}

.w-83 {
  width: 83%;
}

.w-84 {
  width: 84%;
}

.w-85 {
  width: 85%;
}

.w-86 {
  width: 86%;
}

.w-87 {
  width: 87%;
}

.w-88 {
  width: 88%;
}

.w-89 {
  width: 89%;
}

.w-90 {
  width: 90%;
}

.w-91 {
  width: 91%;
}

.w-92 {
  width: 92%;
}

.w-93 {
  width: 93%;
}

.w-94 {
  width: 94%;
}

.w-95 {
  width: 95%;
}

.w-96 {
  width: 96%;
}

.w-97 {
  width: 97%;
}

.w-98 {
  width: 98%;
}

.w-99 {
  width: 99%;
}

.w-100 {
  width: 100%;
}

@media screen and (min-width: 1080px) {
  .w-1__pc {
    width: 1%;
  }
  .w-2__pc {
    width: 2%;
  }
  .w-3__pc {
    width: 3%;
  }
  .w-4__pc {
    width: 4%;
  }
  .w-5__pc {
    width: 5%;
  }
  .w-6__pc {
    width: 6%;
  }
  .w-7__pc {
    width: 7%;
  }
  .w-8__pc {
    width: 8%;
  }
  .w-9__pc {
    width: 9%;
  }
  .w-10__pc {
    width: 10%;
  }
  .w-11__pc {
    width: 11%;
  }
  .w-12__pc {
    width: 12%;
  }
  .w-13__pc {
    width: 13%;
  }
  .w-14__pc {
    width: 14%;
  }
  .w-15__pc {
    width: 15%;
  }
  .w-16__pc {
    width: 16%;
  }
  .w-17__pc {
    width: 17%;
  }
  .w-18__pc {
    width: 18%;
  }
  .w-19__pc {
    width: 19%;
  }
  .w-20__pc {
    width: 20%;
  }
  .w-21__pc {
    width: 21%;
  }
  .w-22__pc {
    width: 22%;
  }
  .w-23__pc {
    width: 23%;
  }
  .w-24__pc {
    width: 24%;
  }
  .w-25__pc {
    width: 25%;
  }
  .w-26__pc {
    width: 26%;
  }
  .w-27__pc {
    width: 27%;
  }
  .w-28__pc {
    width: 28%;
  }
  .w-29__pc {
    width: 29%;
  }
  .w-30__pc {
    width: 30%;
  }
  .w-31__pc {
    width: 31%;
  }
  .w-32__pc {
    width: 32%;
  }
  .w-33__pc {
    width: 33%;
  }
  .w-34__pc {
    width: 34%;
  }
  .w-35__pc {
    width: 35%;
  }
  .w-36__pc {
    width: 36%;
  }
  .w-37__pc {
    width: 37%;
  }
  .w-38__pc {
    width: 38%;
  }
  .w-39__pc {
    width: 39%;
  }
  .w-40__pc {
    width: 40%;
  }
  .w-41__pc {
    width: 41%;
  }
  .w-42__pc {
    width: 42%;
  }
  .w-43__pc {
    width: 43%;
  }
  .w-44__pc {
    width: 44%;
  }
  .w-45__pc {
    width: 45%;
  }
  .w-46__pc {
    width: 46%;
  }
  .w-47__pc {
    width: 47%;
  }
  .w-48__pc {
    width: 48%;
  }
  .w-49__pc {
    width: 49%;
  }
  .w-50__pc {
    width: 50%;
  }
  .w-51__pc {
    width: 51%;
  }
  .w-52__pc {
    width: 52%;
  }
  .w-53__pc {
    width: 53%;
  }
  .w-54__pc {
    width: 54%;
  }
  .w-55__pc {
    width: 55%;
  }
  .w-56__pc {
    width: 56%;
  }
  .w-57__pc {
    width: 57%;
  }
  .w-58__pc {
    width: 58%;
  }
  .w-59__pc {
    width: 59%;
  }
  .w-60__pc {
    width: 60%;
  }
  .w-61__pc {
    width: 61%;
  }
  .w-62__pc {
    width: 62%;
  }
  .w-63__pc {
    width: 63%;
  }
  .w-64__pc {
    width: 64%;
  }
  .w-65__pc {
    width: 65%;
  }
  .w-66__pc {
    width: 66%;
  }
  .w-67__pc {
    width: 67%;
  }
  .w-68__pc {
    width: 68%;
  }
  .w-69__pc {
    width: 69%;
  }
  .w-70__pc {
    width: 70%;
  }
  .w-71__pc {
    width: 71%;
  }
  .w-72__pc {
    width: 72%;
  }
  .w-73__pc {
    width: 73%;
  }
  .w-74__pc {
    width: 74%;
  }
  .w-75__pc {
    width: 75%;
  }
  .w-76__pc {
    width: 76%;
  }
  .w-77__pc {
    width: 77%;
  }
  .w-78__pc {
    width: 78%;
  }
  .w-79__pc {
    width: 79%;
  }
  .w-80__pc {
    width: 80%;
  }
  .w-81__pc {
    width: 81%;
  }
  .w-82__pc {
    width: 82%;
  }
  .w-83__pc {
    width: 83%;
  }
  .w-84__pc {
    width: 84%;
  }
  .w-85__pc {
    width: 85%;
  }
  .w-86__pc {
    width: 86%;
  }
  .w-87__pc {
    width: 87%;
  }
  .w-88__pc {
    width: 88%;
  }
  .w-89__pc {
    width: 89%;
  }
  .w-90__pc {
    width: 90%;
  }
  .w-91__pc {
    width: 91%;
  }
  .w-92__pc {
    width: 92%;
  }
  .w-93__pc {
    width: 93%;
  }
  .w-94__pc {
    width: 94%;
  }
  .w-95__pc {
    width: 95%;
  }
  .w-96__pc {
    width: 96%;
  }
  .w-97__pc {
    width: 97%;
  }
  .w-98__pc {
    width: 98%;
  }
  .w-99__pc {
    width: 99%;
  }
  .w-100__pc {
    width: 100%;
  }
}
.emphasis {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .emphasis {
    font-size: 1.6666666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .emphasis {
    font-size: 2rem;
  }
}
.emphasis::before, .emphasis::after {
  display: block;
  content: "";
  width: 100%;
  max-width: 2rem;
  aspect-ratio: 1;
  background: url(../../common/svg/emphasis.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.emphasis::before {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -100%);
}
.emphasis::after {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -100%) scaleX(-1);
}
.emphasis.__other::before, .emphasis.__other::after {
  display: block;
  content: "";
  width: 100%;
  max-width: 1.3125rem;
  aspect-ratio: 1;
  background: url(../../common/svg/emphasis_other.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.emphasis.__other::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-150%, -50%) scaleX(-1);
}
.emphasis.__other::after {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(150%, -50%);
}

.underLine {
  background: linear-gradient(transparent 80%, #FFDF18 80%);
}
.underLine.__orange {
  background: linear-gradient(transparent 80%, #F6C562 80%);
}

.c__blue {
  color: #009CFF;
}

.c__red {
  color: #FF0009;
}

.button {
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 9999px;
  background: #FFDF18;
  padding: 1.25rem;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 769px) {
  .button {
    font-size: 0.9516837482vw;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .button {
    font-size: 0.8125rem;
  }
}
@media screen and (min-width: 1920px) {
  .button {
    font-size: 1.0416666667vw;
  }
}
@media screen and (min-width: 1920px) and (min-width: 1920px) {
  .button {
    font-size: 1.25rem;
  }
}
.button i {
  padding-right: 0.625rem;
}
.button:hover {
  background: #272343;
  color: #fff;
}

.fukidashi {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.0625rem solid #000;
  border-radius: 9999px;
  background: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
}
.fukidashi::before, .fukidashi::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}
.fukidashi::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.625rem 0.5625rem 0 0.5625rem;
  border-color: #000 transparent transparent transparent;
}
.fukidashi::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.5625rem 0.5rem 0 0.5rem;
  border-color: #fff transparent transparent transparent;
}

.accordion {
  filter: drop-shadow(0.375rem 0.375rem 0 rgba(0, 0, 0, 0.3));
}
.accordion .accordion__inner {
  position: relative;
  width: 100%;
}
.accordion .accordion__button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 11;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__button {
    top: 5rem;
    right: 3.125rem;
    left: auto;
    bottom: auto;
    transform: translate(0, -50%);
  }
}
.accordion .accordion__button .accordion__icon {
  display: grid;
  place-items: center;
  color: #76BB35;
  background: #fff;
  width: 2.625rem;
  aspect-ratio: 1;
  border-radius: 100%;
  font-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  border: 0.375rem solid #76BB35;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__button .accordion__icon {
    width: 2.875rem;
    border: none;
  }
}
.accordion .accordion__button.is_expanded .accordion__icon {
  transform: rotate(180deg);
}
.accordion .accordion__heading {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  padding: 3.125rem 1.25rem 0;
  background: #76BB35;
  border-radius: 1.875rem 1.875rem 0 0;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__heading {
    padding: 2.25rem 3.125rem 0;
    grid-template-columns: 9.75rem 1fr 2.875rem;
  }
}
.accordion .accordion__heading .accordion__tag {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  color: #FF3250;
  background: #fff;
  border: 0.25rem solid #FF3250;
  border-radius: 0.625rem;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__heading .accordion__tag {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    padding: 0.875rem 1rem;
  }
}
.accordion .accordion__heading .accordion__tag .strong {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__heading .accordion__tag .strong {
    font-size: 3.125rem;
  }
}
.accordion .accordion__heading .accordion__tag .normal {
  padding-top: 0.375rem;
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__heading .accordion__tag .normal {
    font-size: 0.9375rem;
  }
}
.accordion .accordion__heading .accordion__title {
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 700;
  color: #fff;
  line-height: 1.7;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__heading .accordion__title {
    font-size: 1.25rem;
  }
}
.accordion .accordion__panel {
  position: relative;
  border: 0.375rem solid #76BB35;
  border-top: 0;
  border-radius: 0 0 1.875rem 1.875rem;
  z-index: 10;
  background: #76BB35;
  padding-top: 2.125rem;
}
@media screen and (min-width: 769px) {
  .accordion .accordion__panel {
    padding-top: 1.875rem;
  }
}
.accordion .accordion__item {
  position: relative;
  margin-top: 0;
  padding: 0 2.5rem;
  line-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: line-height 0.3s ease-out, opacity 0.1s linear, visibility 0.1s linear;
  font-size: 0.875rem;
}
.accordion .is_open .accordion__heading {
  padding-bottom: 2.125rem;
}
@media screen and (min-width: 769px) {
  .accordion .is_open .accordion__heading {
    padding-bottom: 1.875rem;
  }
}
.accordion .is_open .accordion__panel {
  padding-top: 0;
  background: #fff;
}
.accordion .is_open .accordion__item {
  font-size: 0.9375rem;
  padding: 2rem 2.5rem;
  line-height: 1.8;
  opacity: 1;
  visibility: visible;
  transition: line-height 0.3s ease-out, opacity 0.1s linear 0.1s, visibility 0.1s linear 0.1s;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading .heading__text {
  text-align: center;
}
.heading .heading__img {
  position: relative;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
}
.heading .heading_search {
  width: 100%;
  max-width: 8.5rem;
  aspect-ratio: 3.5974025974;
}
@media screen and (min-width: 769px) {
  .heading .heading_search {
    max-width: 16.5rem;
  }
}
.heading .heading_step {
  max-width: 5.25rem;
  aspect-ratio: 2.5454545455;
}
@media screen and (min-width: 769px) {
  .heading .heading_step {
    max-width: 10.125rem;
  }
}
.heading .heading_archivements {
  max-width: 17.25rem;
  aspect-ratio: 8.3636363636;
}
@media screen and (min-width: 769px) {
  .heading .heading_archivements {
    max-width: 33.25rem;
  }
}
.heading .heading_voice {
  width: 100%;
  max-width: 6.625rem;
  aspect-ratio: 3.2121212121;
}
@media screen and (min-width: 769px) {
  .heading .heading_voice {
    max-width: 12.625rem;
  }
}
.heading .heading_faq {
  width: 100%;
  max-width: 4.0625rem;
  aspect-ratio: 1.9696969697;
}
@media screen and (min-width: 769px) {
  .heading .heading_faq {
    max-width: 7.8125rem;
  }
}
.heading .heading_web {
  width: 100%;
  max-width: 8.375rem;
  aspect-ratio: 4.0606060606;
}
@media screen and (min-width: 769px) {
  .heading .heading_web {
    max-width: 16.3125rem;
  }
}

.subText {
  font-size: 1.0625rem;
  font-weight: 700;
  text-align: center;
  margin-top: 1.75rem;
  line-height: 1.5294117647;
}
@media screen and (min-width: 769px) {
  .subText {
    font-size: 1.5625rem;
  }
}

.heading_lv1 {
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.72;
  text-align: center;
}

.faqBox .faqBox__container {
  display: grid;
  grid-template-columns: 1.875rem 1fr;
  gap: 1rem;
}
@media screen and (min-width: 769px) {
  .faqBox .faqBox__container {
    gap: 1.8125rem 1rem;
    grid-template-columns: 2.1875vw 1fr;
    padding: 1.25rem 0;
  }
}
@media screen and (min-width: 1920px) {
  .faqBox .faqBox__container {
    grid-template-columns: 2.625rem 1fr;
  }
}
.faqBox .faqBox__container.__question {
  padding-top: 0;
  padding-bottom: 1.5rem;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .faqBox .faqBox__container.__question {
    padding-bottom: 1.25rem;
  }
}
.faqBox .faqBox__container.__question .faqBox__icon {
  background: #76BB35;
}
@media screen and (min-width: 769px) {
  .faqBox .faqBox__container.__question .faqBox__text {
    font-size: 1.125rem;
  }
}
.faqBox .faqBox__container.__answer {
  border-top: 0.1875rem dotted #FF0009;
  border-bottom: 0.125rem solid #C9C9C9;
  padding-bottom: 2rem;
  padding-top: 1.5rem;
}
@media screen and (min-width: 769px) {
  .faqBox .faqBox__container.__answer {
    padding-top: 1.25rem;
  }
}
.faqBox .faqBox__container.__answer .faqBox__icon {
  background: #F6C562;
}
.faqBox .faqBox__container .faqBox__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 100%;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .faqBox .faqBox__container .faqBox__icon {
    width: 2.1875vw;
    height: 2.1875vw;
    font-size: 1.40625vw;
  }
}
@media screen and (min-width: 1920px) {
  .faqBox .faqBox__container .faqBox__icon {
    width: 2.625rem;
    height: 2.625rem;
    font-size: 1.6875rem;
  }
}
.faqBox .faqBox__text {
  font-size: 0.875rem;
  line-height: 1.8;
}
@media screen and (min-width: 769px) {
  .faqBox .faqBox__text {
    font-size: 0.9375rem;
  }
}
.faqBox:last-child .__answer {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

/**************************\
  Basic Modal Styles
\**************************/
.modal {
  position: relative;
  z-index: 10000;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #E3F6F5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  background-color: #fff;
  padding: 30px;
  max-width: 500px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__header .modal__close:before {
  content: "✕";
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

.tab {
  /* タブメニュー */
  /* タブパネル */
}
.tab .tab__menu {
  display: flex;
  justify-content: center;
}
.tab .tab__menu_item {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 1.6875rem 0 1.375rem;
}
.tab .tab__menu_item:first-of-type {
  background: #F6C562;
}
.tab .tab__menu_item:last-of-type {
  background: #A4D78A;
}
@media screen and (min-width: 769px) {
  .tab .tab__menu_item {
    min-height: 6.25vw;
    padding: 1rem 1.9791666667vw;
    font-size: 1.1458333333vw;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .tab .tab__menu_item {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1920px) {
  .tab .tab__menu_item {
    min-height: 7.5rem;
    padding: 1rem 2.375rem;
    font-size: 1.375rem;
  }
}
.tab .tab__menu_item span {
  padding-left: 0.4166666667vw;
}
.tab .tab__menu_item .tab__menu_icon {
  position: relative;
  width: 100%;
}
.tab .tab__menu_item[data-tab="01"] .tab__menu_icon {
  max-width: 1.4375rem;
  aspect-ratio: 0.7931034483;
}
.tab .tab__menu_item[data-tab="02"] .tab__menu_icon {
  max-width: 1.5rem;
  aspect-ratio: 1.2;
}
.tab .tab__panel_box {
  padding: 1.125rem;
  width: 100%;
  aspect-ratio: 0.8;
}
@media screen and (min-width: 769px) {
  .tab .tab__panel_box {
    aspect-ratio: 1.7767653759;
    padding: 1.0416666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .tab .tab__panel_box {
    padding: 1.25rem;
  }
}
.tab .tab__panel_box > div {
  width: 100%;
  height: 100%;
  background: #fff;
}
.tab .tab__panel_box.is-show {
  display: block;
}
.tab .tab__panel_box01,
.tab .tab__panel_box02 {
  display: none;
}
.tab .tab__panel_box01 {
  background: #F6C562;
}
.tab .tab__panel_box02 {
  background: #A4D78A;
}

.gmapballoon {
  font-size: 0.875rem;
  font-weight: 500;
  display: grid;
  grid-template-areas: "areaA" "areaB" "areaC";
  gap: 0.75rem 1.125rem;
}
.gmapballoon + .gmapballoon {
  margin-top: 3em;
}
.gmapballoon .areaA {
  grid-area: areaA;
  display: flex;
  align-items: center;
  gap: 0.875rem;
  padding-bottom: 0.75rem;
  border-bottom: 0.0625rem solid #000;
}
.gmapballoon .areaB {
  grid-area: areaB;
  display: grid;
  grid-template-columns: 7.5rem 1fr;
  gap: 0.75rem;
}
.gmapballoon .areaC {
  grid-area: areaC;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.375rem;
}
.gmapballoon .gmapbtn {
  position: relative;
  width: 100%;
  aspect-ratio: 3.4912280702;
}
.gmapballoon .gmapbtn img {
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.gmapballoon .gmapballoon__shubetsu {
  display: inline-block;
  text-align: center;
  color: #fff;
  background: #76BB35;
  padding: 0.125rem 0.625rem;
}
.gmapballoon .gmapballoon__shubetsu + .gmapballoon__shiku {
  margin-top: 0.625rem;
}
.gmapballoon .gmapballoon__shubetsu.shubetsu1101, .gmapballoon .gmapballoon__shubetsu.shubetsu1104 {
  background: #76BB35;
}
.gmapballoon .gmapballoon__shubetsu.shubetsu1201 {
  background: #E53170;
}
.gmapballoon .gmapballoon__shiku {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #272343;
  padding: 0.4375rem 0.625rem;
}
.gmapballoon .gmaptitle {
  font-size: 0.875rem;
  font-weight: 700;
}
.gmapballoon .gmap_img {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
}
.gmapballoon .gmap_img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}
.gmapballoon .subtitle {
  padding-right: 0.5em;
  margin-right: 0.5em;
  font-weight: 700;
  font-size: 0.875rem;
  color: #000;
}
.gmapballoon.single {
  display: grid;
  grid-template-columns: 7.5rem 1fr;
  grid-template-areas: "areaB areaB" "areaA areaC";
  gap: 0.75rem 1.125rem;
}
.gmapballoon.single .msg {
  display: none;
}
.gmapballoon.single .areaA {
  grid-area: areaA;
}
.gmapballoon.single .areaB {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  padding-bottom: 0.75rem;
  border-bottom: 0.0625rem solid #000;
  grid-area: areaB;
}
.gmapballoon.single .areaC {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.875rem;
  grid-area: areaC;
}

.splide__slide {
  position: relative;
  background: #fff;
}
.splide__slide img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-slider .splide__slide a::before {
  display: block;
  content: "";
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  width: 1.875rem;
  height: 1.875rem;
  background: url(../../common/svg/icon_zoom.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .main-slider .splide__slide a::before {
    right: 1rem;
    bottom: 1rem;
    width: 2.375rem;
    height: 2.375rem;
  }
}
.main-slider .main-slider__index {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .main-slider .main-slider__index {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    display: flex;
    justify-content: space-between;
    background: #76BB35;
    padding: 0.5rem 1.125rem;
    margin-top: 1.375rem;
    border-radius: 0;
  }
}
.main-slider .splide__arrow {
  width: 1.875rem;
  height: 1.875rem;
  opacity: 1;
  background: url(../../common/svg/splide_arrow01.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (min-width: 769px) {
  .main-slider .splide__arrow {
    width: 2.375rem;
    height: 2.375rem;
  }
}
.main-slider .splide__arrow--next {
  font-size: 0.5rem;
  transform: scaleX(-100%) translateY(-50%);
}
@media screen and (min-width: 769px) {
  .main-slider .splide__arrow--next {
    font-size: 1em;
  }
}

.thumbnail-slider {
  display: none;
  margin: 1.375rem 1.75rem 0;
}
@media screen and (min-width: 769px) {
  .thumbnail-slider {
    display: block;
  }
}
.thumbnail-slider .splide__arrow--prev {
  left: -1.75rem;
}
.thumbnail-slider .splide__arrow--next {
  right: -1.75rem;
  transform: scaleX(-100%) translateY(-50%);
}
.thumbnail-slider .splide__arrow {
  width: 1rem;
  height: 3.4375rem;
  border-radius: 0;
  opacity: 1;
  background: url(../../common/svg/splide_arrow02.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 1rem 0;
  background: #FF0009;
  color: #fff;
  font-size: 1.0625rem;
  min-height: 2.5rem;
}
.header .header__logo {
  display: block;
  position: absolute;
  top: 0.75rem;
  left: 1.875rem;
  width: 100%;
  max-width: 10.25rem;
  aspect-ratio: 2.6428571429;
}
@media screen and (min-width: 1080px) {
  .header .header__logo {
    max-width: 17.34375vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header__logo {
    max-width: 20.8125rem;
  }
}
.header .header__heading {
  display: none;
}
@media screen and (min-width: 1080px) {
  .header .header__heading {
    display: block;
    margin-left: 2.5rem;
    padding-left: 17.34375vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header__heading {
    padding-left: 20.8125rem;
  }
}
.header .header__buttons {
  display: flex;
  gap: 0.25rem;
  position: absolute;
  top: 0.75rem;
  right: 1.875rem;
  width: 100%;
  max-width: 7.875rem;
}
@media screen and (min-width: 1080px) {
  .header .header__buttons {
    max-width: 16.9791666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header__buttons {
    max-width: 20.375rem;
  }
}
.header .header__search,
.header .header__contact {
  position: relative;
  width: 100%;
  max-width: 3.9375rem;
  aspect-ratio: 1.0862068966;
}
@media screen and (min-width: 1080px) {
  .header .header__search,
  .header .header__contact {
    max-width: 8.4895833333vw;
    aspect-ratio: 1.2936507937;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background: #A4D78A;
  padding-top: 3.5rem;
  overflow: hidden;
}
.footer .footer__inner {
  position: relative;
  z-index: 10;
  background: #FF0009;
  padding: 1.25rem 1.125rem 4.75rem;
  width: 100%;
}
@media screen and (min-width: 1080px) {
  .footer .footer__inner {
    padding: 1.25rem 2.25rem;
  }
}
.footer .footer__img {
  position: relative;
  width: 100%;
  max-width: 6.3125rem;
  aspect-ratio: 0.6095505618;
  margin-bottom: -1.25rem;
}
@media screen and (min-width: 769px) {
  .footer .footer__img {
    max-width: 13.5625rem;
  }
}
@media screen and (min-width: 1080px) {
  .footer .footer__img {
    transition: transform 0.3s ease-in-out;
    transform: translateY(20%);
  }
  .footer .footer__img:hover {
    transform: translateY(0);
  }
}
.footer .footer__copy {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1080px) {
  .footer .footer__copy {
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer .footer__copy small {
  font-size: 0.6875rem;
  margin-top: 1rem;
}
@media screen and (min-width: 1080px) {
  .footer .footer__copy small {
    margin-top: 0;
    font-size: 1rem;
  }
}
.footer .footer__policy {
  text-decoration: underline;
}
.footer.contact__footer {
  background: #FFDF18;
}

.footer__gotop {
  position: fixed;
  bottom: 6.25rem;
  right: 1.25rem;
}

.footer__button {
  position: fixed;
  bottom: 0.75rem;
  right: 50%;
  transform: translateX(50%);
  width: 100%;
  max-width: 21.3125rem;
  z-index: 100;
}
@media screen and (min-width: 1080px) {
  .footer__button {
    transform: translateX(0);
    bottom: 3.75rem;
    right: 1rem;
    max-width: 20.75rem;
  }
}
.footer__button a {
  display: block;
  aspect-ratio: 6.4339622642;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .footer__button a {
    aspect-ratio: 2.6349206349;
  }
}

#nendebcopy {
  display: none;
}

.toTop {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
}
.toTop.active {
  opacity: 1;
  visibility: visible;
}

.kv {
  background: #FFFAE8;
}
.kv .kv__img {
  position: relative;
  width: 100%;
  aspect-ratio: 0.7995735608;
}
@media screen and (min-width: 769px) {
  .kv .kv__img {
    aspect-ratio: 1.384083045;
  }
}
.kv .kv__icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 40%);
  width: 100%;
  max-width: 3.8125rem;
  aspect-ratio: 0.9078014184;
}
@media screen and (min-width: 1080px) {
  .kv .kv__icon {
    max-width: 6.6666666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .kv .kv__icon {
    max-width: 8rem;
  }
}

.about .about__heading {
  font-size: 0.9375rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 1080px) {
  .about .about__heading {
    font-size: 1.8125rem;
  }
}
.about .about__heading span {
  padding-bottom: 0.5em;
}
.about .about__text {
  font-size: 0.9375rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.8;
  margin-top: 2rem;
  letter-spacing: -0.05em;
}
@media screen and (min-width: 1080px) {
  .about .about__text {
    margin-top: 3.125rem;
    font-size: 1.5625rem;
  }
}
.about .about__text .large {
  letter-spacing: 0.1em;
  line-height: 1.3;
  font-size: 1.875rem;
  font-weight: 900;
}
@media screen and (min-width: 1080px) {
  .about .about__text .large {
    font-size: 3.875rem;
  }
}
.about .about__text .middle {
  line-height: 1.65;
  font-size: 1.25rem;
}
@media screen and (min-width: 1080px) {
  .about .about__text .middle {
    font-size: 2.8125rem;
  }
}
.about .about__comic {
  position: relative;
  width: 100%;
  aspect-ratio: 0.3653635367;
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .about .about__comic {
    margin-top: 8.75rem;
  }
}

.search .search__container {
  position: relative;
  margin-top: 2.25rem;
  background: #A3D7F4;
  aspect-ratio: 0.5924170616;
}
@media screen and (min-width: 769px) {
  .search .search__container {
    margin-top: 3.75rem;
    aspect-ratio: 1.5384615385;
  }
}
.search .search__container .search__bg01 {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 11.5rem;
  aspect-ratio: 0.5525525526;
}
@media screen and (min-width: 769px) {
  .search .search__container .search__bg01 {
    display: block;
    top: 1.5rem;
    right: 1.9791666667vw;
    transform: translateY(-100%);
  }
}
@media screen and (min-width: 1920px) {
  .search .search__container .search__bg01 {
    right: 2.375rem;
  }
}
.search .search__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.step {
  position: relative;
}
.step .step__bg01 {
  position: absolute;
  width: 100%;
  top: 2.25rem;
  right: 0;
  transform: translateX(40%);
  max-width: 6.125rem;
  aspect-ratio: 3.1129032258;
}
@media screen and (min-width: 769px) {
  .step .step__bg01 {
    max-width: 12.0625rem;
    top: 7.2916666667vw;
    right: 5.7291666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .step .step__bg01 {
    top: 8.75rem;
    right: 6.875rem;
  }
}
.step .step__bg02 {
  position: absolute;
  width: 100%;
  max-width: 8.5rem;
  aspect-ratio: 2.9565217391;
  display: none;
}
@media screen and (min-width: 769px) {
  .step .step__bg02 {
    display: block;
    bottom: 11.875rem;
    left: 6.5104166667vw;
  }
}
@media screen and (min-width: 1920px) {
  .step .step__bg02 {
    left: 7.8125rem;
  }
}
.step .step__bg03 {
  position: absolute;
  width: 100%;
  aspect-ratio: 0.7277486911;
  z-index: 10;
  bottom: 0.625rem;
  right: 2.625rem;
  max-width: 3.4375rem;
}
@media screen and (min-width: 769px) {
  .step .step__bg03 {
    max-width: 8.6875rem;
    right: 4.4270833333vw;
  }
}
@media screen and (min-width: 1920px) {
  .step .step__bg03 {
    right: 5.3125rem;
  }
}
.step .step__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  margin: 0 auto;
  margin-top: 1.875rem;
  max-width: 21.4375rem;
}
@media screen and (min-width: 769px) {
  .step .step__wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
    margin-top: 1.875rem;
    max-width: 100%;
  }
}
.step .step__wrapper .step__img {
  position: relative;
  width: 100%;
  aspect-ratio: 0.8532338308;
}
@media screen and (min-width: 769px) {
  .step .step__wrapper .step__img {
    aspect-ratio: 0.4773413897;
  }
}
.step .step__container {
  background: #fff;
  padding: 2.5rem 1.75rem;
  border-radius: 1.25rem;
  margin-top: 3.5rem;
}
@media screen and (min-width: 769px) {
  .step .step__container {
    padding: 3.125rem 3.75rem;
  }
}
.step .step__container .step__logos {
  position: relative;
  width: 100%;
  max-width: 38.25rem;
  aspect-ratio: 2.5555555556;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .step .step__container .step__logos {
    aspect-ratio: 12;
  }
}
.step .step__container .step__text {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
  line-height: 1.6666666667;
}
@media screen and (min-width: 769px) {
  .step .step__container .step__text {
    font-size: 0.9375rem;
    margin-top: 2rem;
  }
}
.step .step__container .step__text .small {
  font-weight: 300;
}

.archivements {
  position: relative;
}
.archivements .archivements__bg01 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0.625rem);
  width: 100%;
  max-width: 4.6875rem;
  aspect-ratio: 1.0331491713;
}
@media screen and (min-width: 769px) {
  .archivements .archivements__bg01 {
    max-width: 11.6875rem;
    bottom: -1.4375rem;
    left: 4.7916666667vw;
    transform: translate(0, 0);
  }
}
@media screen and (min-width: 1920px) {
  .archivements .archivements__bg01 {
    left: 5.75rem;
  }
}
.archivements .archivements__container {
  background: #fff;
  padding: 1.875rem 1.25rem;
  border-radius: 1.25rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 3.25rem;
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .archivements .archivements__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 5.625rem;
    margin-top: 4.75rem;
    padding: 3.125rem;
  }
}
.archivements .archivements__img {
  position: relative;
  width: 100%;
  aspect-ratio: 0.6661184211;
}
.archivements .archivements__img:first-of-type {
  position: relative;
}
.archivements .archivements__img:first-of-type::before {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.0625rem;
  border-bottom: 0.125rem dotted #707070;
  transform: translate(0, 1.625rem);
}
@media screen and (min-width: 769px) {
  .archivements .archivements__img:first-of-type::before {
    left: auto;
    right: 0;
    width: 0.0625rem;
    height: 100%;
    border-bottom: none;
    border-left: 0.125rem dotted #707070;
    transform: translate(2.8125rem, 0);
  }
}

.voice {
  background: #FFFAE8;
}
.voice .voice__container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4.25rem;
  margin-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .voice .voice__container {
    gap: 1rem;
    margin-top: 4.625rem;
  }
}

.faq .faq__container {
  display: grid;
  gap: 2rem;
  padding: 2.375rem 1.25rem;
  background: #fff;
  border-radius: 1.5rem;
  margin-top: 10rem;
}
@media screen and (min-width: 769px) {
  .faq .faq__container {
    padding: 1.6875rem 2.0625rem 3.375rem;
  }
}

.web .web__movie {
  position: relative;
  width: 100%;
  aspect-ratio: 1.7794392523;
  border: 0.5rem solid #fff;
  border-radius: 1.25rem;
  margin-top: 2.25rem;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .web .web__movie {
    border: 1.5rem solid #fff;
    margin-top: 3.5rem;
  }
}
.web .web__text {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.8;
  margin-top: 1.6875rem;
}
@media screen and (min-width: 769px) {
  .web .web__text {
    margin-top: 3.75rem;
    font-size: 1.5625rem;
  }
}
.web .web__text strong {
  font-size: 1.2em;
}
.web .web__contact {
  position: relative;
  width: 100%;
  aspect-ratio: 5.5;
  margin-top: 2.25rem;
}
@media screen and (min-width: 1080px) {
  .web .web__contact {
    aspect-ratio: 7.7692307692;
    margin-top: 3.125rem;
  }
}

.kv {
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 769px) {
  .kv {
    padding-bottom: 5rem;
  }
}

.search {
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .search {
    padding-top: 7.5rem;
  }
}

.step {
  background: #A3D7F4;
  padding-top: 3.75rem;
  padding-bottom: 7.375rem;
  overflow-x: hidden;
}
@media screen and (min-width: 769px) {
  .step {
    padding-bottom: 17.5rem;
  }
}

.archivements {
  position: relative;
  background: #FFDF18;
  padding-bottom: 5.75rem;
}
@media screen and (min-width: 769px) {
  .archivements {
    padding-bottom: 10.5rem;
  }
}
.archivements::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50vw 11.6666666667vw 50vw;
  border-color: transparent transparent #FFDF18 transparent;
  transform: translateY(-100%);
  z-index: 10;
}

.voice {
  padding-top: 5.625rem;
  padding-bottom: 6.625rem;
}
@media screen and (min-width: 769px) {
  .voice {
    padding-bottom: 0;
    padding-top: 5.625rem;
  }
}

.faq {
  padding-top: 8.75rem;
}
@media screen and (min-width: 769px) {
  .faq {
    padding-top: 22.5rem;
  }
}

.web {
  margin-top: 12.5rem;
}
@media screen and (min-width: 769px) {
  .web {
    margin-top: 22.25rem;
  }
}

.bg01 {
  background: url(../img/top/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}

.bg02 {
  background: url(../img/top/bg02_sp.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
}
@media screen and (min-width: 769px) {
  .bg02 {
    background-image: url(../img/top/bg02.png);
    background-size: 100% 100%;
  }
}

.map .map__inner {
  position: relative;
  overflow: hidden;
}
.map #map_menu {
  width: 100%;
  height: 100%;
  top: 0;
}
.map #map_menu .sub_nav {
  border: none;
}

.tabSearch {
  padding: 1.25rem;
}
@media screen and (min-width: 769px) {
  .tabSearch {
    padding: 3rem;
  }
}
.tabSearch .sub_nav {
  width: 100%;
}
.tabSearch .tabSearch__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "areaA" "areaH" "areaB" "areaC" "areaD" "areaE" "areaF" "areaG";
  gap: 0;
}
@media screen and (min-width: 769px) {
  .tabSearch .tabSearch__wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "areaA areaB" "areaC areaC" "areaD areaE" "areaF areaF" "areaG areaG";
    gap: 0 2.5rem;
  }
}
.tabSearch .tabSearch__wrapper .areaA {
  grid-area: areaA;
}
.tabSearch .tabSearch__wrapper .areaB {
  grid-area: areaB;
}
.tabSearch .tabSearch__wrapper .areaC {
  grid-area: areaC;
}
.tabSearch .tabSearch__wrapper .areaD {
  grid-area: areaD;
}
.tabSearch .tabSearch__wrapper .areaE {
  grid-area: areaE;
}
.tabSearch .tabSearch__wrapper .areaF {
  grid-area: areaF;
}
.tabSearch .tabSearch__wrapper .areaG {
  grid-area: areaG;
}
.tabSearch .tabSearch__wrapper .areaH {
  grid-area: areaH;
  font-size: 0.75rem;
}
.tabSearch .tabSearch__wrapper .tabSearch__input {
  margin: 0;
  padding: 1.25rem 0;
  border-top: 0.0625rem solid #C9C9C9;
}
@media screen and (min-width: 769px) {
  .tabSearch .tabSearch__wrapper .tabSearch__input {
    padding: 1.5rem 2rem;
  }
}
.tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__input_heading {
  padding-bottom: 1em;
}
.tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__input_inner {
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
@media screen and (min-width: 769px) {
  .tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__input_inner {
    gap: 2.5rem;
  }
}
.tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__input_inner select {
  width: 50%;
}
.tabSearch .tabSearch__wrapper .tabSearch__input select {
  position: relative;
  border: 0.0625rem solid #707070;
  border-radius: 0.5rem;
  padding: 1rem 1.125rem;
  width: 100% !important;
}
.tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__checkList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
@media screen and (min-width: 769px) {
  .tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__checkList {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1080px) {
  .tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__checkList {
    grid-template-columns: repeat(6, 1fr);
  }
}
.tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__checkList.__col7 {
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 769px) {
  .tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__checkList.__col7 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1080px) {
  .tabSearch .tabSearch__wrapper .tabSearch__input .tabSearch__checkList.__col7 {
    grid-template-columns: repeat(7, 1fr);
  }
}
.tabSearch .tabSearch__wrapper .tabSearch__lists {
  display: flex;
  flex-wrap: wrap;
  gap: 1em 1.5em;
}
.tabSearch .tabSearch__wrapper .tabSearch__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
  background: #EAF4E1;
}
.tabSearch .tabSearch__wrapper .tabSearch__btn .tabSearch__btn_inner {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  max-width: 15.5625rem;
  aspect-ratio: 3.9523809524;
}
.tabSearch .madorimap_cb,
.tabSearch .setsubimap_cb {
  display: flex;
  gap: 0.625rem 0;
  flex-wrap: wrap;
}
.tabSearch .madorimap_cb > span,
.tabSearch .setsubimap_cb > span {
  width: 12.5rem;
}
.tabSearch .madorimap_cb > span {
  width: 6.25rem;
}

#map_canvas {
  position: relative;
}
#map_canvas #no-results-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 1.25rem;
  text-align: center;
  background: #fff;
  color: #FF0009;
  font-weight: 1.25rem;
  font-weight: 700;
  border: 0.0625rem solid #FF0009;
}

.archive {
  padding: 7.5rem 0 3.75rem;
  background: #FFFAE8;
}
@media screen and (min-width: 769px) {
  .archive {
    padding: 7.5rem 0;
  }
}
.archive .archive__title {
  display: flex;
  line-height: 1.5;
}
.archive .archive__title > span {
  display: inline-block;
  padding-left: 0.5em;
  margin-top: 0.2em;
}
.archive .archive__list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem 0;
  margin-top: 2.5rem;
}
.archive .archive__item {
  padding: 0 1.25rem;
  padding-top: 2.5rem;
  border-top: 0.0625rem solid #333333;
}
.archive .archive__item:last-child {
  padding-bottom: 2.5rem;
  border-bottom: 0.0625rem solid #333333;
}
.archive .archive_box {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "areaA" "areaB";
  gap: 1.25rem 2.5rem;
  margin-top: 1em;
}
@media screen and (min-width: 1080px) {
  .archive .archive_box {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-template-areas: "areaA areaB";
  }
}
.archive .archive_box .archive_box__img {
  grid-area: areaA;
}
.archive .archive_box .archive_box__detailInner {
  display: flex;
  flex-direction: column;
  grid-area: areaB;
  background: #fff;
  padding: 2em;
  box-sizing: border-box;
}
.archive .archive_box .archive_box__buttons {
  display: flex;
  flex-direction: column;
  gap: 0.625rem 1.25rem;
  margin: 0 auto;
  margin-top: auto;
}
@media screen and (min-width: 769px) {
  .archive .archive_box .archive_box__buttons {
    flex-direction: row;
    max-width: 100%;
  }
}
.archive .archive_box .archive_box__buttons > a {
  transition: opacity 0.3s ease-in;
}
.archive .archive_box .archive_box__buttons > a:hover {
  opacity: 0.7;
}
.archive .archive_box .archive_box__buttons img {
  width: 100%;
  height: auto;
}
.archive .archive_box .archive_box__dataList {
  display: grid;
  gap: 0.625rem 1.25rem;
  grid-template-columns: 1fr;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .archive .archive_box .archive_box__dataList {
    grid-template-columns: 7.5rem 1fr;
  }
}
.archive .archive_box .archive_box__dataList > dt {
  font-weight: 700;
  border-right: none;
}
@media screen and (min-width: 769px) {
  .archive .archive_box .archive_box__dataList > dt {
    border-right: 0.0625rem solid #333333;
    border-top: none;
  }
}
.archive .archive_box .archive_box__dataList._col {
  grid-template-columns: 1fr;
}
.archive .archive_box .archive_box__dataList + .archive_box__dataList {
  margin-top: 0.625rem;
}
.archive .archive_box .archive_box__dataList:last-of-type {
  margin-bottom: 1.25rem;
}
.archive .page-title {
  font-size: 1.5rem;
  line-height: 2;
}
.archive .page-title span {
  padding-left: 1em;
}
.archive .nav-previous {
  display: flex;
  gap: 1em;
  padding-top: 1rem;
}
.archive .nav-previous .nav-previous_title {
  font-size: 1.1em;
  font-weight: 700;
}
.archive .page_navigation_wrap {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
@media screen and (min-width: 769px) {
  .archive .page_navigation_wrap {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.archive .page_navigation_wrap .search_btn {
  margin: 0 auto;
}
.archive .navigation {
  width: 100%;
}
.archive .nav-next {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 1em;
  padding-top: 1rem;
}
@media screen and (min-width: 769px) {
  .archive .nav-next {
    justify-content: flex-start;
  }
}
.archive .nav-next > b {
  color: #FF0009;
  font-weight: 400;
}
.archive .nav-next > b,
.archive .nav-next > a {
  background: #fff;
  padding: 0.5em;
  border: 0.0625rem solid #333333;
  border-radius: 0.125rem;
}
.archive .nav-next > a {
  transition: opacity 0.3s ease-in;
}
.archive .nav-next > a:hover {
  opacity: 0.7;
}
.archive .entry-title {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  font-size: 1.25rem;
  line-height: 2;
}
.archive .box2image {
  width: 100%;
  height: auto;
}

.btn_back {
  z-index: 11;
  position: fixed;
  right: 1.25rem;
  bottom: 6.25rem;
  border-radius: 100%;
  border: 0.125rem solid #333333;
  background: #76BB35;
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
}
@media screen and (min-width: 769px) {
  .btn_back {
    bottom: 12.5rem;
    width: 5rem;
    height: 5rem;
  }
}
.btn_back > img {
  width: 50%;
  height: auto;
}
.btn_back > span {
  font-size: 0.625rem;
  color: #fff;
}
.btn_back.active {
  opacity: 1;
  visibility: visible;
}

.tag {
  color: #fff;
  background: #000;
  font-size: 0.875rem;
  font-weight: 400;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  border-radius: 0.25rem;
}
@media screen and (min-width: 769px) {
  .tag {
    padding: 0 1.25rem;
  }
}
.tag.shubetsu {
  background: #76BB35;
}

.new_mark {
  background: #FF0009;
  color: #fff;
  font-size: 0.625rem;
  font-weight: 700;
  height: 1.25rem;
  padding: 0 0.625rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article {
  padding: 5.625rem 0;
  line-height: 2;
  background: #FFFAE8;
}
@media screen and (min-width: 769px) {
  .article {
    padding: 11.25rem 0;
  }
}
.article .article_title {
  font-size: 1.5rem;
}
@media screen and (min-width: 769px) {
  .article .article_title {
    font-size: 2.25rem;
  }
}
.article .article_description {
  margin-top: 2em;
  font-size: 1rem;
}
@media screen and (min-width: 769px) {
  .article .article_description {
    font-size: 1.25rem;
  }
}
.article .single__contact_wrapper {
  padding-top: 0 !important;
}
@media screen and (min-width: 769px) {
  .article .single__contact_wrapper {
    padding-top: 0 !important;
  }
}

.policy_list {
  margin-top: 3em;
  counter-reset: number 0;
}
.policy_list .policy_item {
  line-height: 2;
  padding-left: 1.5em;
  position: relative;
  font-size: 0.875rem;
}
@media screen and (min-width: 769px) {
  .policy_list .policy_item {
    font-size: 1rem;
  }
}
.policy_list .policy_item::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  counter-increment: number 1;
  content: counter(number) ".";
}
.single .single__heading,
.contact .single__heading {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  background: #fff;
  border: 0.3125rem solid #FF0009;
  border-radius: 0.625rem;
  padding: 1.75rem 2.25rem;
  filter: drop-shadow(0.25rem 0.25rem 0 rgba(0, 0, 0, 0.3));
}
@media screen and (min-width: 769px) {
  .single .single__heading,
  .contact .single__heading {
    flex-wrap: nowrap;
    gap: 0.625rem;
    padding: 1.75rem 2.25rem;
  }
}
.single .single__heading .single__shubetsu,
.contact .single__heading .single__shubetsu {
  color: #fff;
  background: #76BB35;
  padding: 0.25rem 1rem;
}
.single .single__heading .single__shubetsu.shubetsu1101,
.contact .single__heading .single__shubetsu.shubetsu1101 {
  background: #76BB35;
}
.single .single__heading .single__shozaichi,
.contact .single__heading .single__shozaichi {
  color: #fff;
  background: #4D4D4D;
  padding: 0.25rem 1rem;
}
.single .single__heading .single__title,
.contact .single__heading .single__title {
  font-size: 1.375rem;
  font-weight: 600;
}
.single .single__bukken_copy,
.contact .single__bukken_copy {
  padding: 2.25rem 0;
  font-size: 1.25rem;
}
.single .single__wrapper,
.contact .single__wrapper {
  position: relative;
  padding-top: 5.375rem;
  padding-bottom: 1.25rem;
  background: #FFFAE8;
}
@media screen and (min-width: 769px) {
  .single .single__wrapper,
  .contact .single__wrapper {
    padding-bottom: 4.625rem;
    padding-top: 8.75rem;
  }
}
.single .single__wrapper .single__bg,
.contact .single__wrapper .single__bg {
  display: none;
  position: absolute;
  bottom: 0;
  right: 2.5rem;
  width: 100%;
  max-width: 7.125rem;
  aspect-ratio: 0.4160583942;
  transform: translateY(30%);
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .single .single__wrapper .single__bg,
  .contact .single__wrapper .single__bg {
    display: block;
    right: 4.9479166667vw;
  }
}
@media screen and (min-width: 1920px) {
  .single .single__wrapper .single__bg,
  .contact .single__wrapper .single__bg {
    right: 5.9375rem;
  }
}
.single .single__container,
.contact .single__container {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 1080px) {
  .single .single__container,
  .contact .single__container {
    gap: 2.5rem;
    grid-template-columns: 1fr 1fr;
  }
}
.single .single__thumbnail img,
.contact .single__thumbnail img {
  width: 100%;
  height: auto;
}
.single .single__right,
.contact .single__right {
  background: #fff;
  padding: 2rem 1.25rem;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  line-height: 1.3;
}
@media screen and (min-width: 769px) {
  .single .single__right,
  .contact .single__right {
    margin-top: 0;
    padding: 1.75rem;
  }
}
.single .single__right .single__right_inner,
.contact .single__right .single__right_inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.625rem;
  border-top: 0.0625rem solid #C9C9C9;
  padding: 0.875rem 0;
}
@media screen and (min-width: 769px) {
  .single .single__right .single__right_inner,
  .contact .single__right .single__right_inner {
    gap: 0;
    grid-template-columns: 5.375rem 1fr;
  }
}
.single .single__right .single__right_inner.strong,
.contact .single__right .single__right_inner.strong {
  display: block;
  font-weight: 700;
  border: none;
}
.single .single__right .single__right_inner.strong .single__right_title,
.contact .single__right .single__right_inner.strong .single__right_title {
  padding-bottom: 0.875rem;
}
.single .single__right .single__right_inner.strong .single__right_text,
.contact .single__right .single__right_inner.strong .single__right_text {
  font-size: 1.375rem;
  color: #FF0009;
  border-top: 0.0625rem solid #C9C9C9;
  padding-top: 0.875rem;
}
.single .single__right .single__right_title,
.contact .single__right .single__right_title {
  font-size: 0.75rem;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .single .single__right .single__right_title,
  .contact .single__right .single__right_title {
    font-size: 0.9375rem;
  }
}
.single .single__right .single__right_text,
.contact .single__right .single__right_text {
  font-size: 0.75rem;
}
@media screen and (min-width: 769px) {
  .single .single__right .single__right_text,
  .contact .single__right .single__right_text {
    font-size: 0.9375rem;
  }
}
.single .single__right .single__right_text .small,
.contact .single__right .single__right_text .small {
  font-size: 0.7em;
}
.single .single__button,
.contact .single__button {
  width: 100%;
  max-width: 37.5rem;
  margin: 0 auto;
  margin-top: 0;
}
@media screen and (min-width: 1080px) {
  .single .single__button,
  .contact .single__button {
    max-width: 63.125rem;
    margin-top: -11.6666666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .single .single__button,
  .contact .single__button {
    margin-top: -14rem;
  }
}
.single .single__button a,
.contact .single__button a {
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 5.5;
  background: url(../img/common/img_contact_sp.png);
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 769px) {
  .single .single__button a,
  .contact .single__button a {
    aspect-ratio: 7.7692307692;
    background-image: url(../img/common/img_contact.png);
  }
}
.single .single__notice,
.contact .single__notice {
  background: #fff;
  line-height: 1.5;
  border-radius: 1.25rem;
}
@media screen and (min-width: 769px) {
  .single .single__notice,
  .contact .single__notice {
    border-radius: 1.25rem;
    padding: 3.125rem;
    margin-top: 5rem;
  }
}
.single .single__detail,
.contact .single__detail {
  position: relative;
  background: #FFDF18;
  padding-top: 5.125rem;
}
@media screen and (min-width: 769px) {
  .single .single__detail,
  .contact .single__detail {
    padding-top: 11.6666666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .single .single__detail,
  .contact .single__detail {
    padding-top: 14rem;
  }
}
.single .single__detail::before,
.contact .single__detail::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11.6666666667vw 50vw 0 50vw;
  border-color: #FFFAE8 transparent transparent transparent;
}
.single .single__detail .single__detail_wrapper,
.contact .single__detail .single__detail_wrapper {
  position: relative;
  z-index: 10;
  padding-bottom: 1rem;
}
@media screen and (min-width: 769px) {
  .single .single__detail .single__detail_wrapper,
  .contact .single__detail .single__detail_wrapper {
    padding-bottom: 0;
  }
}
.single .single__data .single__data_heading,
.contact .single__data .single__data_heading {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .single .single__data .single__data_heading,
  .contact .single__data .single__data_heading {
    margin-top: 3.125rem;
  }
}
.single .single__data .single__data_wrapper,
.contact .single__data .single__data_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 2.25rem;
  background: #fff;
  border-radius: 1.25rem;
  padding: 2.375rem 1.25rem;
  margin-top: 0.75rem;
}
@media screen and (min-width: 769px) {
  .single .single__data .single__data_wrapper,
  .contact .single__data .single__data_wrapper {
    padding: 3.125rem;
    margin-top: 1.875rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
.single .single__data .single__data_inner .single__data_container,
.contact .single__data .single__data_inner .single__data_container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.75rem;
  border-top: 0.0625rem solid #C9C9C9;
  gap: 0.5rem;
}
@media screen and (min-width: 769px) {
  .single .single__data .single__data_inner .single__data_container,
  .contact .single__data .single__data_inner .single__data_container {
    gap: 0rem;
    grid-template-columns: 6.875rem 1fr;
    padding: 0.9375rem 1rem 0.9375rem 0;
  }
}
.single .single__data .single__data_inner .single__data_container:last-of-type,
.contact .single__data .single__data_inner .single__data_container:last-of-type {
  border-bottom: 0.0625rem solid #C9C9C9;
}
.single .single__data .single__data_inner .single__data_title,
.single .single__data .single__data_inner .single__data_text,
.contact .single__data .single__data_inner .single__data_title,
.contact .single__data .single__data_inner .single__data_text {
  font-size: 0.9375rem;
}
.single .single__data .single__data_inner .single__data_title,
.contact .single__data .single__data_inner .single__data_title {
  font-weight: 700;
}
.single .single__data .single__data_inner .single__data_text,
.contact .single__data .single__data_inner .single__data_text {
  line-height: 1.3;
}
.single .single__data .single__data_inner .single__data_text .setsubi_dat,
.contact .single__data .single__data_inner .single__data_text .setsubi_dat {
  display: inline-block;
  position: relative;
  padding-left: 1em;
}
.single .single__data .single__data_inner .single__data_text .setsubi_dat::before,
.contact .single__data .single__data_inner .single__data_text .setsubi_dat::before {
  position: absolute;
  top: 50%;
  left: 0.3em;
  transform: translate(-50%, -50%);
  display: block;
  content: "・";
}
.single .single__map,
.contact .single__map {
  position: relative;
  padding-top: 5.625rem;
  background: #A3D7F4;
}
@media screen and (min-width: 769px) {
  .single .single__map,
  .contact .single__map {
    padding-top: 3.75rem;
    padding-top: 14.7916666667vw;
    margin-top: -8.3333333333vw;
  }
}
@media screen and (min-width: 1920px) {
  .single .single__map,
  .contact .single__map {
    padding-top: 3.75rem;
    padding-top: 17.75rem;
    margin-top: -10rem;
  }
}
.single .single__map::before,
.contact .single__map::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11.6666666667vw 50vw 0 50vw;
  border-color: #FFDF18 transparent transparent transparent;
}
.single .single__map .single__map_wrapper,
.contact .single__map .single__map_wrapper {
  margin-top: 3.625rem;
}
.single .single__map .single__map_inner,
.contact .single__map .single__map_inner {
  width: 100%;
  aspect-ratio: 2.6809651475;
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 769px) {
  .single .single__map .single__map_inner,
  .contact .single__map .single__map_inner {
    padding-bottom: 0;
  }
}
.single .single__map .single__map_container,
.contact .single__map .single__map_container {
  background: #fff;
  border-radius: 1.25rem;
  padding: 1.875rem 1.25rem;
}
@media screen and (min-width: 769px) {
  .single .single__map .single__map_container,
  .contact .single__map .single__map_container {
    padding: 3.75rem 3.125rem;
  }
}
.single .single__map .single__mapSub,
.contact .single__map .single__mapSub {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media screen and (min-width: 769px) {
  .single .single__map .single__mapSub,
  .contact .single__map .single__mapSub {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.6875rem;
  }
}
.single .single__map .single__mapSub .single__mapSub_info,
.contact .single__map .single__mapSub .single__mapSub_info {
  display: grid;
  grid-template-columns: 8.375rem 1fr;
  gap: 1rem;
}
@media screen and (min-width: 769px) {
  .single .single__map .single__mapSub .single__mapSub_info,
  .contact .single__map .single__mapSub .single__mapSub_info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
.single .single__map .single__mapSub .single__mapSub_img,
.contact .single__map .single__mapSub .single__mapSub_img {
  position: relative;
  width: 100%;
  aspect-ratio: 1.5988700565;
}
.single .single__map .single__mapSub .single__mapSub_img .img,
.contact .single__map .single__mapSub .single__mapSub_img .img {
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single .single__map .single__mapSub .single__mapSub_text,
.contact .single__map .single__mapSub .single__mapSub_text {
  line-height: 1.3;
}
.single .single__map .single__mapSub_list,
.contact .single__map .single__mapSub_list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.625rem 3.75rem;
  background: #E1F2FB;
  padding: 1rem;
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .single .single__map .single__mapSub_list,
  .contact .single__map .single__mapSub_list {
    grid-template-columns: repeat(2, 1fr);
    padding: 1.75rem 1.875rem;
    margin-top: 3.125rem;
  }
}
.single .single__map .single__mapSub_list .single__mapSub_item,
.contact .single__map .single__mapSub_list .single__mapSub_item {
  position: relative;
  padding-left: 1.2em;
  line-height: 1.3;
}
.single .single__map .single__mapSub_list .single__mapSub_item::before,
.contact .single__map .single__mapSub_list .single__mapSub_item::before {
  display: block;
  content: "";
  position: absolute;
  top: 0.1em;
  left: 0;
  width: 0.8em;
  height: 0.8em;
  border-radius: 100%;
  background: #000;
}
.single .single__map .map_canvas,
.contact .single__map .map_canvas {
  margin-top: 1.375rem;
}
.single .single__map .single__map_imageList #single_fudou_img,
.contact .single__map .single__map_imageList #single_fudou_img {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.375rem;
  margin-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media screen and (min-width: 769px) {
  .single .single__map .single__map_imageList #single_fudou_img,
  .contact .single__map .single__map_imageList #single_fudou_img {
    grid-template-columns: repeat(6, 1fr);
    gap: 1.0416666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .single .single__map .single__map_imageList #single_fudou_img,
  .contact .single__map .single__map_imageList #single_fudou_img {
    gap: 1.25rem;
  }
}
.single .single__map .single__map_imageList #single_fudou_img a,
.contact .single__map .single__map_imageList #single_fudou_img a {
  display: block;
  position: relative;
  aspect-ratio: 1.5888888889;
  overflow: hidden;
}
.single .single__map .single__map_imageList #single_fudou_img a img,
.contact .single__map .single__map_imageList #single_fudou_img a img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}
.single .wpcf7-spinner,
.contact .wpcf7-spinner {
  display: none;
}
.single .single__submitBtn,
.contact .single__submitBtn {
  background: none;
}

.single .single__contact {
  background: #A4D78A;
}
@media screen and (min-width: 769px) {
  .single .single__contact {
    background-size: 100% 16.875rem;
  }
}
.single .single__contact_wrapper {
  position: relative;
  padding-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .single .single__contact_wrapper {
    padding-top: 15.25rem;
  }
}
.single .single__contact_wrapper::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11.6666666667vw 50vw 0 50vw;
  border-color: #A3D7F4 transparent transparent transparent;
}

.single .single__contact_inner,
.contact .single__contact_inner {
  background: #fff;
  padding: 1.25rem;
  border-radius: 1.25rem;
  margin-top: 1.5rem;
}
@media screen and (min-width: 769px) {
  .single .single__contact_inner,
  .contact .single__contact_inner {
    padding: 3.125rem 3.5rem;
  }
}
.single .single__contact_input,
.contact .single__contact_input {
  display: none;
}
.single .single__contact_submit,
.contact .single__contact_submit {
  width: 100%;
  max-width: 18.75rem;
  aspect-ratio: 4;
  margin: 0 auto;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .single .single__contact_submit,
  .contact .single__contact_submit {
    margin-top: 3.125rem;
    max-width: 17rem;
    aspect-ratio: 3.2380952381;
  }
}
.single .single__contact_submit > p,
.contact .single__contact_submit > p {
  display: block;
  width: 100%;
  height: 100%;
}
.single .single__contact_submit input,
.contact .single__contact_submit input {
  color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
  background: url("../svg/btn_submit_sp.svg") no-repeat;
  background-size: contain;
}
@media screen and (min-width: 769px) {
  .single .single__contact_submit input,
  .contact .single__contact_submit input {
    background: url("../svg/btn_submit.svg") no-repeat;
  }
}
.single .single__contact_policy,
.contact .single__contact_policy {
  margin-top: 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .single .single__contact_policy,
  .contact .single__contact_policy {
    margin-top: 3.9375rem;
  }
}
.single .single__contact_policy a,
.contact .single__contact_policy a {
  text-decoration: underline;
  color: #A4D78A;
}
.single label,
.contact label {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}
@media screen and (min-width: 769px) {
  .single label,
  .contact label {
    grid-template-columns: 12.5rem 1fr;
  }
}
.single label span + span,
.contact label span + span {
  display: flex;
  flex-direction: column;
}
.single input,
.single textarea,
.contact input,
.contact textarea {
  width: 100%;
  padding: 0.875rem 1.625rem;
  font-size: 1.125rem;
  background: #F4F4F4;
  font-weight: 400;
}
.single textarea,
.contact textarea {
  border-radius: 0.625rem;
}
.single .required,
.contact .required {
  color: #FF0009;
  font-size: 0.875rem;
}
.single .wpcf7-form > p,
.contact .wpcf7-form > p {
  margin-top: 2.375rem;
}
.single .screen-reader-response,
.single .wpcf7-response-output,
.contact .screen-reader-response,
.contact .wpcf7-response-output {
  display: none;
}
.single .wpcf7-not-valid-tip,
.contact .wpcf7-not-valid-tip {
  font-size: 0.75rem;
  font-weight: 700;
  color: #FF0009;
  margin-top: 1em;
}

.contact .single__contact {
  background: linear-gradient(180deg, #FFFAE8 0%, #FFFAE8 50%, #FFDF18 50%, #FFDF18 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.contact .single__contact_wrapper {
  position: relative;
  padding-top: 6.25rem;
}
@media screen and (min-width: 769px) {
  .contact .single__contact_wrapper {
    padding-top: 12.5rem;
  }
}
.contact .single__contact_wrapper::before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11.6666666667vw 50vw 0 50vw;
  border-color: #FFFAE8 transparent transparent transparent;
}
.contact .single__contact_inner {
  position: relative;
  background: #fff;
  padding: 1.25rem;
  border-radius: 1.25rem;
  margin-top: 3rem;
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .contact .single__contact_inner {
    margin-top: 4.5rem;
    padding: 3.125rem 3.5rem;
  }
}
.contact .single__contact_heading .heading_contact {
  width: 100%;
  max-width: 10rem;
  aspect-ratio: 4.1428571429;
}
@media screen and (min-width: 769px) {
  .contact .single__contact_heading .heading_contact {
    max-width: 19.9375rem;
  }
}
.contact .single__contact_bg {
  display: none;
  position: absolute;
  top: 40%;
  width: 100%;
  max-width: 9.1875rem;
  aspect-ratio: 0.7313432836;
}
@media screen and (min-width: 769px) {
  .contact .single__contact_bg {
    display: block;
    left: 4.6875vw;
  }
}
@media screen and (min-width: 769px) {
  .contact .single__contact_bg {
    left: 5.625rem;
  }
}