@use '../../base/vars'as *;
@use "sass:math";

.archivements {
    position: relative;

    .archivements__bg01 {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, rem(10));
        width: 100%;
        max-width: rem(75);
        aspect-ratio: calc(187/181);


        @include media(sm) {
            max-width: rem(187);
            bottom: rem(-23);
            left: vw(92);
            transform: translate(0, 0);
        }

        @include media(xl) {
            left: rem(92);
        }
    }

    .archivements__container {
        background: $white;
        padding: rem(30) rem(20);
        border-radius: rem(20);
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(52);
        margin-top: rem(40);

        @include media(sm) {
            grid-template-columns: repeat(2, 1fr);
            gap: rem(90);
            margin-top: rem(76);
            padding: rem(50);
        }
    }

    .archivements__img {
        position: relative;
        width: 100%;
        aspect-ratio: calc(405/608);

        &:first-of-type {
            position: relative;

            &::before {
                display: block;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: rem(1);
                border-bottom: rem(2) dotted $gray;
                transform: translate(0, rem(26));

                @include media(sm) {
                    left: auto;
                    right: 0;
                    width: rem(1);
                    height: 100%;
                    border-bottom: none;
                    border-left: rem(2) dotted $gray;
                    transform: translate(rem(45), 0);
                }
            }
        }
    }
}
