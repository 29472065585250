@use '../../base/vars'as *;
@use "sass:math";

.single,
.contact {

    .single__contact_wrapper {}

    .single__contact_inner {
        background: $white;
        padding: rem(20);
        border-radius: rem(20);
        margin-top: rem(24);

        @include media(sm) {
            padding: rem(50) rem(56);
        }
    }

    .single__contact_input {
        display: none;
    }

    .single__contact_submit {
        width: 100%;
        max-width: rem(300);
        aspect-ratio: calc(300/75);
        margin: 0 auto;
        margin-top: rem(30);

        @include media(sm) {
            margin-top: rem(50);
            max-width: rem(272);
            aspect-ratio: calc(272/84);
        }

        >p {
            display: block;
            width: 100%;
            height: 100%;
        }


        input {
            color: transparent;
            width: 100%;
            height: 100%;
            border: none;
            padding: 0;
            margin: 0;
            background: url("../svg/btn_submit_sp.svg") no-repeat;
            background-size: contain;

            @include media(sm) {
                background: url("../svg/btn_submit.svg") no-repeat;
            }
        }
    }

    .single__contact_policy {
        margin-top: rem(18);
        font-size: rem(14);
        line-height: 1.5;

        @include media(sm) {
            margin-top: rem(63);
        }

        a {
            text-decoration: underline;
            color: $green_l;
        }
    }

    // contactform7
    label {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(20);

        @include media(sm) {
            grid-template-columns: rem(200) 1fr;
        }

        span {
            +span {
                display: flex;
                flex-direction: column;
            }
        }
    }

    input,
    textarea {
        width: 100%;
        padding: rem(14) rem(26);
        font-size: rem(18);
        background: $gray_ll;
        font-weight: 400;
    }

    textarea {
        border-radius: rem(10);
    }

    .required {
        color: $red;
        font-size: rem(14);
    }

    .wpcf7-form {
        >p {
            margin-top: rem(38);
        }
    }

    .screen-reader-response,
    .wpcf7-response-output {
        display: none;
    }

    .wpcf7-not-valid-tip {
        font-size: rem(12);
        font-weight: 700;
        color: $red;
        margin-top: 1em;
    }
}


.contact {
    .single__contact {
        background: linear-gradient(180deg, $bg-color 0%, $bg-color 50%, $yellow 50%, $yellow 100%);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .single__contact_wrapper {
        position: relative;
        padding-top: rem(100);

        @include media(sm) {
            padding-top: rem(200);
        }

        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: vw(224) vw(800, 1600) 0 vw(800, 1600);
            border-color: $bg-color transparent transparent transparent;
        }
    }

    .single__contact_inner {
        position: relative;
        background: $white;
        padding: rem(20);
        border-radius: rem(20);
        margin-top: rem(48);
        z-index: $main;

        @include media(sm) {
            margin-top: rem(72);
            padding: rem(50) rem(56);
        }
    }

    .single__contact_heading {
        .heading_contact {
            width: 100%;
            max-width: rem(160);
            aspect-ratio: calc(319/77);

            @include media(sm) {
                max-width: rem(319);
            }
        }
    }

    .single__contact_bg {
        display: none;
        position: absolute;
        top: 40%;
        width: 100%;
        max-width: rem(147);
        aspect-ratio: calc(147/201);

        @include media(sm) {
            display: block;
            left: vw(90);
        }

        @include media(sm) {
            left: rem(90);
        }
    }
}
