@use '../../base/vars'as *;
@use "sass:math";

.kv {
    padding-bottom: rem(40);

    @include media(sm) {
        padding-bottom: rem(80);
    }
}

.about {}

.search {
    padding-top: rem(40);

    @include media(sm) {
        padding-top: rem(120);
    }
}

.step {
    background: $blue_l;
    padding-top: rem(60);
    padding-bottom: rem(118);
    overflow-x: hidden;

    @include media(sm) {
        padding-bottom: rem(280);
    }
}

.archivements {
    position: relative;
    background: $yellow;
    padding-bottom: rem(92);

    @include media(sm) {
        padding-bottom: rem(168);
    }

    &::before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 vw(800, 1600) vw(224) vw(800, 1600);
        border-color: transparent transparent $yellow transparent;
        transform: translateY(-100%);
        z-index: $main;
    }
}

.voice {
    padding-top: rem(90);
    padding-bottom: rem(106);

    @include media(sm) {
        padding-bottom: 0;
        padding-top: rem(90);
    }
}

.faq {
    padding-top: rem(140);

    @include media(sm) {
        padding-top: rem(360);
    }
}

.web {
    margin-top: rem(200);
    @include media(sm) {
        margin-top: rem(356);
    }
}

.bg01 {
    background: url(../img/top/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
}

.bg02 {
    background: url(../img/top/bg02_sp.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    
    @include media(sm) {
        background-image: url(../img/top/bg02.png);
        background-size: 100% 100%;
    }
}
