@use '../../../base/vars'as *;
@use "sass:math";

.archive {
    padding: rem(120) 0 rem(60);
    background: $bg-color;

    @include media(sm) {
        padding: rem(120) 0;
    }

    .archive__title {
        display: flex;
        line-height: 1.5;

        >span {
            display: inline-block;
            padding-left: .5em;
            margin-top: .2em;
        }
    }

    .archive__list {
        display: flex;
        flex-direction: column;
        gap: rem(40) 0;
        margin-top: rem(40);
    }

    .archive__item {
        padding: 0 rem(20);
        padding-top: rem(40);
        border-top: rem(1) solid $text;

        &:last-child {
            padding-bottom: rem(40);
            border-bottom: rem(1) solid $text;
        }
    }

    .archive_box {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            "areaA"
            "areaB";
        gap: rem(20) rem(40);
        margin-top: 1em;

        @include media(md) {
            grid-template-columns: repeat(auto-fit, minmax(rem(400), 1fr));
            grid-template-areas:
                "areaA areaB";
        }

        .archive_box__img {
            grid-area: areaA;
        }

        .archive_box__detail {}

        .archive_box__detailInner {
            display: flex;
            flex-direction: column;
            grid-area: areaB;
            background: $white;
            padding: 2em;
            box-sizing: border-box;
        }

        .archive_box__buttons {
            display: flex;
            flex-direction: column;
            gap: rem(10) rem(20);
            margin: 0 auto;
            margin-top: auto;

            @include media(sm) {
                flex-direction: row;
                max-width: 100%;
            }

            >a {
                transition: opacity .3s ease-in;

                &:hover {
                    opacity: .7;
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .archive_box__dataList {
            display: grid;
            gap: rem(10) rem(20);
            grid-template-columns: 1fr;
            line-height: 1.5;

            @include media(sm) {
                grid-template-columns: rem(120) 1fr;
            }

            >dt {
                font-weight: 700;
                border-right: none;

                @include media(sm) {
                    border-right: rem(1) solid $text;
                    border-top: none;
                }
            }

            &._col {
                grid-template-columns: 1fr;
            }

            &+.archive_box__dataList {
                margin-top: rem(10);
            }

            &:last-of-type {
                margin-bottom: rem(20);
            }
        }
    }


    // 既存のクラスに上書き

    .page-title {
        font-size: rem(24);
        line-height: 2;

        span {
            padding-left: 1em;
        }
    }

    .nav-previous {
        display: flex;
        gap: 1em;
        padding-top: rem(16);

        @include media(sm) {}

        .nav-previous_title {
            font-size: 1.1em;
            font-weight: 700;
        }
    }

    .page_navigation_wrap {
        display: flex;
        flex-direction: column;
        gap: rem(20);

        @include media(sm) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }

        .search_btn {
            margin: 0 auto;
        }
    }

    .navigation {
        width: 100%;
    }

    .nav-next {
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 1em;
        padding-top: rem(16);

        @include media(sm) {
            justify-content: flex-start;
        }

        >b {
            color: $red;
            font-weight: 400;
        }

        >b,
        >a {
            background: $white;
            padding: .5em;
            border: rem(1) solid $text;
            border-radius: rem(2);
        }

        >a {
            transition: opacity .3s ease-in;

            &:hover {
                opacity: .7;
            }
        }
    }

    .entry-title {
        display: flex;
        flex-wrap: wrap;
        gap: rem(16);
        align-items: center;
        font-size: rem(20);
        line-height: 2;
    }

    .box2image {
        width: 100%;
        height: auto;
    }
}

.btn_back {
    z-index: $main+1;
    position: fixed;
    right: rem(20);
    bottom: rem(100);
    border-radius: 100%;
    border: rem(2) solid $text;
    background: $green;
    display: block;
    width: rem(60);
    height: rem(60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition:
        opacity .3s ease-in,
        visibility .3s ease-in;

    @include media(sm) {
        bottom: rem(200);
        width: rem(80);
        height: rem(80);
    }

    >img {
        width: 50%;
        height: auto;
    }

    >span {
        font-size: rem(10);
        color: $white;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.tag {
    color: $white;
    background: $black;
    font-size: rem(14);
    font-weight: 400;
    min-height: rem(24);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    border-radius: rem(4);

    @include media(sm) {
        padding: 0 rem(20);
    }

    &.shubetsu {
        background: $green;
    }
}

.new_mark {
    background: $red;
    color: $white;
    font-size: rem(10);
    font-weight: 700;
    height: rem(20);
    padding: 0 rem(10);
    border-radius: rem(4);
    display: flex;
    justify-content: center;
    align-items: center;
}
