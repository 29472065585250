@use '../../base/vars'as *;
@use "sass:math";

.search {

    .search__container {
        position: relative;
        margin-top: rem(36);
        background: $blue_l;
        aspect-ratio: calc(375/633);

        @include media(sm) {
            margin-top: rem(60);
            aspect-ratio: calc(1600/1040);
        }

        .search__bg01 {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            max-width: rem(184);
            aspect-ratio: calc(184/333);

            @include media(sm) {
                display: block;
                top: rem(24);
                right: vw(38);
                transform: translateY(-100%);
            }

            @include media(xl) {
                right: rem(38);
            }
        }
    }

    .search__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;


        @include media(sm) {}
    }
}
