@use '../../../base/vars'as *;
@use "sass:math";

.policy_list {
    margin-top: 3em;
    counter-reset: number 0;

    .policy_item {
        line-height: 2;
        padding-left: 1.5em;
        position: relative;
        font-size: rem(14);

        @include media(sm) {
            font-size: rem(16);
        }

        &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 1em;
            height: 1em;
            counter-increment: number 1;
            content: counter(number) ".";
        }

        >strong {}
    }
}
