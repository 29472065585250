@use '../../base/vars'as *;
@use "sass:math";

.map {
    .map__wrapper {}

    .map__inner {
        position: relative;
        overflow: hidden;
    }

    #map_menu {
        width: 100%;
        height: 100%;
        top: 0;

        .sub_nav {
            border: none;
        }
    }
}


.tabSearch {
    padding: rem(20);

    @include media(sm) {
        padding: rem(48);
    }

    .sub_nav {
        width: 100%;
    }

    .tabSearch__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            'areaA'
            'areaH'
            'areaB'
            'areaC'
            'areaD'
            'areaE'
            'areaF'
            'areaG';
        gap: 0;

        @include media(sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
                'areaA areaB'
                'areaC areaC'
                'areaD areaE'
                'areaF areaF'
                'areaG areaG';
            gap: 0 rem(40);
        }

        .areaA {
            grid-area: areaA;
        }

        .areaB {
            grid-area: areaB;
        }

        .areaC {
            grid-area: areaC;
        }

        .areaD {
            grid-area: areaD;
        }

        .areaE {
            grid-area: areaE;
        }

        .areaF {
            grid-area: areaF;
        }

        .areaG {
            grid-area: areaG;
        }

        .areaH {
            grid-area: areaH;
            font-size: rem(12);
        }

        .tabSearch__input {
            margin: 0;
            padding: rem(20) 0;
            border-top: rem(1) solid $gray_l;

            @include media(sm) {
                padding: rem(24) rem(32);
            }

            .tabSearch__input_heading {
                padding-bottom: 1em;
            }

            .tabSearch__input_inner {
                margin: 0 !important;
                display: flex;
                align-items: center;
                gap: rem(10);

                @include media(sm) {
                    gap: rem(40);
                }

                select {
                    width: 50%;
                }
            }

            select {
                position: relative;
                border: rem(1) solid $gray;
                border-radius: rem(8);
                padding: rem(16) rem(18);
                width: 100% !important;
            }

            .tabSearch__checkList {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: rem(16);

                @include media(sm) {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include media(md) {
                    grid-template-columns: repeat(6, 1fr);
                }

                &.__col7 {
                    grid-template-columns: repeat(2, 1fr);

                    @include media(sm) {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    @include media(md) {
                        grid-template-columns: repeat(7, 1fr);
                    }
                }
            }
        }

        .tabSearch__lists {
            display: flex;
            flex-wrap: wrap;
            gap: 1em 1.5em;
        }

        .tabSearch__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: rem(40) 0;
            background: $green_ll;

            .tabSearch__btn_inner {
                position: relative;
                width: 100% !important;
                height: 100% !important;
                margin: 0 !important;
                padding: 0 !important;
                border: none !important;
                box-shadow: none !important;
                max-width: rem(249);
                aspect-ratio: calc(249/63);
            }
        }
    }

    .madorimap_cb,
    .setsubimap_cb {
        display: flex;
        gap: rem(10) 0;
        flex-wrap: wrap;

        >span {
            width: rem(200);
        }
    }

    .madorimap_cb {
        >span {
            width: rem(100);
        }
    }
}

#map_canvas {
    position: relative;

    #no-results-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        padding: rem(20);
        text-align: center;
        background: $white;
        color: $red;
        font-weight: rem(20);
        font-weight: 700;
        border: rem(1) solid $red;
    }
}
