@use "../../base/vars"as *;
@use "sass:math";

.fukidashi {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: rem(1) solid $black;
    border-radius: 9999px;
    background: $white;
    padding: rem(12) rem(24);
    font-size: rem(20);
    font-weight: 500;

    &::before,
    &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
    }

    &::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem(10) rem(9) 0 rem(9);
        border-color: $black transparent transparent transparent;
    }

    &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem(9) rem(8) 0 rem(8);
        border-color: $white transparent transparent transparent;
    }
}
