@use "sass:math";
@use '../../base/vars'as *;

.accordion {
    filter: drop-shadow(rem(6) rem(6) 0 rgba($black, 0.3));

    .accordion__inner {
        position: relative;
        width: 100%;
    }

    .accordion__button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        z-index: $main + 1;

        @include media(sm) {
            top: rem(80);
            right: rem(50);
            left: auto;
            bottom: auto;
            transform: translate(0, -50%);
        }

        .accordion__icon {
            display: grid;
            place-items: center;
            color: $green;
            background: $white;
            width: rem(42);
            aspect-ratio: calc(46/46);
            border-radius: 100%;
            font-size: rem(20);
            transition: transform .2s ease-in-out;
            border: rem(6) solid $green;

            @include media(sm) {
                width: rem(46);
                border: none;
            }
        }

        &.is_expanded {
            .accordion__icon {
                transform: rotate(180deg);
            }
        }
    }

    .accordion__heading {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(20);
        padding: rem(50) rem(20) 0;
        background: $green;
        border-radius: rem(30) rem(30) 0 0;

        @include media(sm) {
            padding: rem(36) rem(50) 0;
            grid-template-columns: rem(156) 1fr rem(46);
        }

        .accordion__tag {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            padding: rem(12) rem(20);
            color: $red_l;
            background: $white;
            border: rem(4) solid $red_l;
            border-radius: rem(10);

            @include media(sm) {
                position: relative;
                top: 0;
                left: 0;
                transform: translate(0, 0);
                padding: rem(14) rem(16);
            }

            .strong {
                font-size: rem(40);
                font-weight: 400;
                font-family: $font-oswald;

                @include media(sm) {
                    font-size: rem(50);
                }
            }

            .normal {
                padding-top: rem(6);
                font-size: rem(12);
                line-height: calc(20/15);

                @include media(sm) {
                    font-size: rem(15);
                }
            }
        }

        .accordion__title {
            font-size: rem(16);
            line-height: calc(26/16);
            font-weight: 700;
            color: $white;
            line-height: calc(34/20);

            @include media(sm) {
                font-size: rem(20);
            }
        }


        &[aria-hidden="true"] {}
    }

    .accordion__panel {
        position: relative;
        border: rem(6) solid $green;
        border-top: 0;
        border-radius: 0 0 rem(30) rem(30);
        z-index: $main;
        background: $green;
        padding-top: rem(34);

        @include media(sm) {
            padding-top: rem(30);
        }
    }

    .accordion__list {}

    .accordion__item {
        position: relative;
        margin-top: 0;
        padding: 0 rem(40);
        line-height: 0;
        opacity: 0;
        visibility: hidden;
        transition:
            line-height .3s ease-out,
            opacity .1s linear,
            visibility .1s linear;
        font-size: rem(14);
    }

    .accordion__link {}

    .is_open {
        .accordion__heading {
            padding-bottom: rem(34);

            @include media(sm) {
                padding-bottom: rem(30);
            }
        }

        .accordion__panel {
            padding-top: 0;
            background: $white;
        }

        .accordion__item {
            font-size: rem(15);
            padding: rem(32) rem(40);
            line-height: calc(27/15);
            opacity: 1;
            visibility: visible;
            transition: line-height .3s ease-out,
                opacity .1s linear .1s,
                visibility .1s linear .1s;
        }
    }
}
