@use '../base/vars'as *;
@use "sass:math";

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    background: $green_l;
    padding-top: rem(56);
    overflow: hidden;

    .footer__inner {
        position: relative;
        z-index: $main;
        background: $red;
        padding: rem(20) rem(18) rem(76);
        width: 100%;

        @include media(md) {
            padding: rem(20) rem(36);
        }
    }

    .footer__img {
        position: relative;
        width: 100%;
        max-width: rem(101);
        aspect-ratio: calc(217/356);
        margin-bottom: rem(-20);

        @include media(sm) {
            max-width: rem(217);
        }

        @include media(md) {

            transition: transform .3s ease-in-out;
            transform: translateY(20%);

            &:hover {
                transform: translateY(0);
            }
        }
    }

    .footer__copy {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(md) {
            flex-direction: row;
            justify-content: space-between;
        }

        small {
            font-size: rem(11);
            margin-top: rem(16);

            @include media(md) {
                margin-top: 0;
                font-size: rem(16);
            }
        }
    }

    .footer__policy {
        text-decoration: underline;
    }

    &.contact__footer {
        background: $yellow;
    }
}

.footer__gotop {
    position: fixed;
    bottom: rem(100);
    right: rem(20);
}

.footer__button {
    position: fixed;
    bottom: rem(12);
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    max-width: rem(341);
    z-index: $menu;

    @include media(md) {
        transform: translateX(0);
        bottom: rem(60);
        right: rem(16);
        max-width: rem(332);
    }

    a {
        display: block;
        aspect-ratio: calc(341/53);
        position: relative;
        width: 100%;

        @include media(sm) {
            aspect-ratio: calc(332/126);
        }
    }
}

#nendebcopy {
    display: none;
}

.toTop {
    opacity: 0;
    visibility: hidden;
    transition:
        opacity .3s ease-in,
        visibility .3s ease-in;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}
