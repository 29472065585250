@use "sass:math";
@use '../../base/vars'as *;

.tab {

    /* タブメニュー */
    .tab__menu {
        display: flex;
        justify-content: center;
    }

    .tab__menu_item {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding: rem(27) 0 rem(22);

        &:first-of-type {
            background: $orange;
        }

        &:last-of-type {
            background: $green_l;
        }

        @include media(sm) {
            min-height: vw(120);
            padding: rem(16) vw(38, $viewport);
            @include font-vw(22, $viewport);
        }

        @include media(xl) {
            min-height: rem(120);
            padding: rem(16) rem(38);
            font-size: rem(22);
        }

        span {
            padding-left: vw(8, $viewport);
        }


        .tab__menu_icon {
            position: relative;
            width: 100%;
        }

        &[data-tab="01"] {
            .tab__menu_icon {
                max-width: rem(23);
                aspect-ratio: calc(23/29);
            }
        }

        &[data-tab="02"] {
            .tab__menu_icon {
                max-width: rem(24);
                aspect-ratio: calc(24/20);
            }
        }
    }


    /* タブパネル */
    .tab__panel {}

    .tab__panel_box {
        padding: rem(18);
        width: 100%;
        aspect-ratio: calc(400/500);

        @include media(sm) {
            aspect-ratio: calc(1560/878);
            padding: vw(20);
        }

        @include media(xl) {
            padding: rem(20);
        }

        >div {
            width: 100%;
            height: 100%;
            background: $white;
        }

        &.is-show {
            display: block;
        }
    }

    .tab__panel_box01,
    .tab__panel_box02 {
        display: none;
    }

    .tab__panel_box01 {
        background: $orange;
    }

    .tab__panel_box02 {
        background: $green_l;
    }
}
